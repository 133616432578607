import { useState, useEffect } from "react";

import MsalLogin from "./components/MsalLogin";
import Firebase from "./components/Firebase";
import { MsalProvider } from "@azure/msal-react";
import { getMessaging, getToken, onMessage} from 'firebase/messaging';
import {FIREBACE_VAPIDKEY} from "./services/apiConfig";

function App({ instance }) {
  const messaging = getMessaging();
  getToken(messaging, { vapidKey: FIREBACE_VAPIDKEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        localStorage.setItem("deviceToken", currentToken);
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
    onMessage((payload) => {
      console.log('Message received:', payload);
      // Handle incoming messages here
    });

  return (
    <MsalProvider instance={instance}>
      <MsalLogin />
    </MsalProvider>
  );
}

export default App;
