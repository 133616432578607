import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setClientGroups } from "../../../store/features/generalSlice";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import ResourseTypeTab from "./ResourseTypeTab";
import AccordionArrow from "../../../assets/theme/images/down-arrow.svg";
import { searchClientWorkspaces } from "../../../services";
import { LoaderAcc } from "../../../assets/theme/images/icons";
// import ClientGroupTabs from "./ClientGroupTabs";

function CustomToggle({ children, eventKey, clientId }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    // console.log("totally custom!", clientId)
    getPbiResources(clientId)
  );

  async function getPbiResources(key) {
    //console.log("resourceId", general?.clientGroups[0].clientId);
  }

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
function ClientWorkspacesAccordian({ clientId }) {
  const general = useSelector((state) => state.general);
  const workapaces =
    general?.wholeData &&
    general?.wholeData[clientId] &&
    general?.wholeData[clientId]["workapaces"]
      ? general.wholeData[clientId]["workapaces"]
      : [];
  if (!general?.clientGroups) {
    console.log("ClientWorkspacesAccordian");
    return false;
  }

  return (
    <Accordion defaultActiveKey="0">
      <div className="clients-tab-inner">
        {workapaces.length > 0 &&
          workapaces.map((item) => (
            <Card key={item.id}>
              <Card.Header>
                <CustomToggle eventKey={item.id} clientId={clientId}>
                  {item.name}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={item.id}>
                <Card.Body>
                  <ResourseTypeTab workspaceId={item.id} clientId={clientId} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        {general?.activityLoader && (
          <Card>
            <Card.Body className="page-accordion-wrap">
              <div className="acc-loader">
                <LoaderAcc />                
              </div>              
            </Card.Body>
          </Card>
        )}
        {workapaces.length <= 0 && (
          <Card>
            <Card.Body className="page-accordion-wrap">
              No Result found!           
            </Card.Body>
          </Card>
        )}
      </div>
    </Accordion>
  );
}

export default ClientWorkspacesAccordian;
