import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

import Eye from '../../../assets/theme/images/eye.png';
import Export from '../../../assets/theme/images/export.png';

import {
  Info,
  Pin,
  Edit,
  TrashTwo,
  ExportIcn,
  ReportOne,
  Preview
} from '../../../assets/theme/images/icons';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function VisualsAccordion() {
  return (
    <Accordion defaultActiveKey="">
      <Card>
        <Card.Header>        
          <CustomToggle eventKey="0"><Preview/> Visuals 1</CustomToggle>
          <div className="accordion-actions">
              <span>20 Aug 2023 - 02:10PM</span>
              <div className="buttons">
                  <a href="#"><img src={Eye} alt="" /> View</a>
                  {/* <a href="#" className="export"><img src={Export} alt="" /> Export</a> */}
              </div>
              <div className="icons">
                  <a href="#" data-bs-toggle="modal"
                      data-bs-target="#exampleModal">
                        <Info/>
                  </a>
                  <a href="#">
                  <Pin/>
                  </a>
                  <a href="#">
                  <Edit/>
                  </a>
                  <a href="#">
                  <TrashTwo/>
                  </a>
                  <a href="#">
                  <ExportIcn/>
                  </a>
              </div>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>Hello! I'm the body</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="1"><Preview/> Visuals 2</CustomToggle>
          <div className="accordion-actions">
              <span>20 Aug 2023 - 02:10PM</span>
              <div className="buttons">
                  <a href="#"><img src={Eye} alt="" /> View</a>
                  {/* <a href="#" className="export"><img src={Export} alt="" /> Export</a> */}
              </div>
              <div className="icons">
                  <a href="#" data-bs-toggle="modal"
                      data-bs-target="#exampleModal">
                        <Info/>
                  </a>
                  <a href="#">
                  <Pin/>
                  </a>
                  <a href="#">
                  <Edit/>
                  </a>
                  <a href="#">
                  <TrashTwo/>
                  </a>
                  <a href="#">
                  <ExportIcn/>
                  </a>
              </div>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>Hello! I'm another body</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default VisualsAccordion;
