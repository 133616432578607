import { configureStore, combineReducers } from "@reduxjs/toolkit";
import generalReducer from "./features/generalSlice";
import counterReducer from "./features/counterSlice";
import userReducer from "./features/userSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// export const store = configureStore({
//   reducer: {
//     general: generalReducer,
//     counter: counterReducer,
//     user: userReducer,
//   },
// });

const rootReducer = combineReducers({
  general: generalReducer,
  counter: counterReducer,
  user: userReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist:['']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
