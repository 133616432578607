// ImagePopup.js
import React from "react";
import "../../assets/theme/css/ImagePopup.css";
import { Close2, Download2 } from "../../../src/assets/theme/images/icons";

const AlertMessage = ({ alertType, alertMessage }) => {
  console.log(alertType, alertMessage);
  return (
    <>
      {alertType == "success" && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <div className="alert-message">{alertMessage}</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
      {alertType == "error" && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <div className="alert-message">{alertMessage}</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}
    </>
  );
};

export default AlertMessage;
