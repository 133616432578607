import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  searchClientSecurityGroups,
  searchClientUsers,
  createClientUserSecurityGroupLink,
  searchClients,
} from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientGroupMap({ instance }) {
  const [data, setData] = useState(false);
  const [clientUsers, setClientUsers] = useState(false);
  const [clientGroups, setClientGroups] = useState(false);
  const [clientList, setClientList] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const { clientId, resourceId } = useParams();
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        //dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  const mapClientGroup = (params) => {
    const user = {
      token: accessToken,
    };
    createClientUserSecurityGroupLink(clientId, params, user)
      .then((res) => {
        let succesMessage = "Client user group maping created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };
  async function getClientGroups(otherClientId = null) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let fetchClientId = clientId;
    if (otherClientId !== null) {
      fetchClientId = otherClientId;
    }
    const response = await searchClientSecurityGroups(fetchClientId, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientGroups(results);
  }
  async function getClientUsers(otherClientId = null) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let fetchClientId = clientId;
    if (otherClientId !== null) {
      fetchClientId = otherClientId;
    }
    const response = await searchClientUsers(fetchClientId, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientUsers(results);
  }
  const getClientList = async () => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClients("", user);
    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientList(results);
  };

  useEffect(() => {
    let userData = localStorage.getItem("pbUser");
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let accessToken = "";
    if (userData) {
      userData = JSON.parse(userData);
      accessToken = userData.accessToken;
    } else {
      userData = null;
    }
    const user = {
      token: accessToken,
    };
    setAccessToken(accessToken);
    let ignore = false;
    if (accessToken != "") {
      getUserData();
      getClientUsers();
      getClientGroups();
      getClientList();
    }
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Client User Group Map </h1>
            {alertMessage && (
              <div className="alert alert-success" role="alert">
                <div className="alert-message">{alertMessage}</div>
              </div>
            )}
            {error && (
              <div className="alert alert-danger" role="alert">
                <div className="alert-message">{error}</div>
              </div>
            )}

            <div className="row">
              <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-header border-bottom">
                    <h5 className="card-title mb-0">Client User Group Map</h5>
                    <div>
                      <div className="d-flex flex-wrap"></div>
                    </div>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        clientIdForGroup: "",
                        clientIdForUser: "",
                        securityGroupId: "6b9cada1-8720-4c2b-141c-08dbc8a94ae5",
                        userId: "66526a7b-773d-4055-a52c-08dbc14f7b60",
                      }}
                      onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        mapClientGroup(JSON.stringify(values, null, 2));
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <div className="col-md-12">
                            <label className="form-label">Client</label>
                            {clientList && (
                              <Field
                                name="clientIdForGroup"
                                className="form-control"
                                as="select"
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  setFieldValue(
                                    "clientIdForGroup",
                                    selectedValue
                                  );
                                  getClientGroups(e.target.value);
                                }}
                              >
                                <option key="" value="">
                                  Select Client
                                </option>
                                {clientList.map((option) => {
                                  return (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </option>
                                  );
                                })}
                              </Field>
                            )}
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Security Group</label>
                            {clientGroups && (
                              <Field
                                name="securityGroupId"
                                className="form-control"
                                as="select"
                              >
                                <option key="" value="">
                                  Select Security Group
                                </option>
                                {clientGroups.map((option) => {
                                  return (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </option>
                                  );
                                })}
                              </Field>
                            )}
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Client</label>
                            {clientList && (
                              <Field
                                name="clientIdForUser"
                                className="form-control"
                                as="select"
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  setFieldValue(
                                    "clientIdForUser",
                                    selectedValue
                                  );
                                  getClientUsers(selectedValue);
                                }}
                              >
                                <option key="" value="">
                                  Select Client
                                </option>
                                {clientList.map((option) => {
                                  return (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </option>
                                  );
                                })}
                              </Field>
                            )}
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Users</label>

                            {clientUsers && (
                              <Field
                                name="userId"
                                className="form-control"
                                as="select"
                              >
                                <option key="" value="">
                                  Select Users
                                </option>
                                {clientUsers.map((option) => {
                                  return (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </option>
                                  );
                                })}
                              </Field>
                            )}
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>

                          <div className="col-md-12">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                            <Link
                              className="btn btn-secondary"
                              to={`/client-security-groups/${clientId}`}
                            >
                              Cancel
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientGroupMap;
