import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import SliderImageReport from "../../../assets/theme/images/Report-1.png";
import SliderImageDashboard from "../../../assets/theme/images/Dashboard.png";
import SliderImageQna from "../../../assets/theme/images/qna.png";

// import {
//   Close
// } from '../../../assets/theme/images/icons';

function MyFavourites() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    lap: {
      breakpoint: { max: 1300, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (

        <Col className="top-slider">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}            
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerclassName="carousel-container"
            removeArrowOnDeviceType={["", ""]}            
            dotListclassName="custom-dot-list-style"
            itemclassName="carousel-item-padding-40-px"
          >
            <div>
            <a href="#" className="cell">
              <div className="closeIcon">
                {/* <Close /> */}
              </div>
                <img src={SliderImageReport} alt="" />
                <div className="text">
                    <span className="title">Report</span>
                    <p>Report 1</p>
                    <span className="name">Group Name</span>
                    <span className="time-date">20 Aug 2023 - 02:10PM</span>
                </div>
            </a>
            </div>
            <div>
            <a href="#" className="cell">
            <div className="closeIcon">
                {/* <Close /> */}
              </div>
                <img src={SliderImageDashboard} alt="" />
                <div className="text">
                    <span className="title">Dashboard</span>
                    <p>Dashboard 2</p>
                    <span className="name">Group Name</span>
                    <span className="time-date">20 Aug 2023 - 02:10PM</span>
                </div>
            </a>
            </div>
            <div>
            <a href="#" className="cell">
            <div className="closeIcon">
                {/* <Close /> */}
              </div>
                <img src={SliderImageQna} alt="" />
                <div className="text">
                    <span className="title">QnA</span>
                    <p>QnA 3</p>
                    <span className="name">Group Name</span>
                    <span className="time-date">20 Aug 2023 - 02:10PM</span>
                </div>
            </a>
            </div>
            <div>
            <a href="#" className="cell">
            <div className="closeIcon">
                {/* <Close /> */}
              </div>
                <img src={SliderImageDashboard} alt="" />
                <div className="text">
                    <span className="title">Dashboard</span>
                    <p>Dashboard 2</p>
                    <span className="name">Group Name</span>
                    <span className="time-date">20 Aug 2023 - 02:10PM</span>
                </div>
            </a>
            </div>
          </Carousel>
          
        </Col>

  );
}

export default MyFavourites;
