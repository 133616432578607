import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import {
  getEmbedToken,
  getResourceEmbedToken,
} from "../../services/clientAdmin";
import { getEmbedToken as pbiGetEmbedToken } from "../../services";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../store/features/generalSlice";

function WidgetEmbededView({ pbiResource, clientData }) {
  const general = useSelector((state) => state.general);
  const [powerBIEmbedView, setPowerBIEmbedView] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [report, setReport] = useState();
  const [showLoader, setShowLoader] = useState(true);

  const [userEmail, setUserEmail] = useState("aidi_testemployee_1@dapasol.com");
  const [embedToken, setEmbedToken] = useState(false);
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function getPages(embeddedReport) {
    console.log("report", report);
  }
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
      }
    }
    return pbUser;
  }
  let body = {};

  async function getEmbedTokens() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }   
    
    let token = '';
    token = getResourceEmbedToken(pbiResource.id, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        token = res.data.token;
        return res.data.token;
        
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
      console.log(token);
      return token;
  }
  async function getVisualsData() {
    // Get the pages from the report
    await report
      .getPages()
      .then((pages) => {
        // Display information about each page
        pages.forEach(async (page) => {
          console.log(`Page name: ${page.displayName}`);
          console.log(`Page ID: ${page.name}`);
          console.log(`IsActive: ${page.isActive}`);

          const visuals = await page.getVisuals();
          console.log("visuals", visuals);

          visuals.forEach((visual) => {
            console.log("visual", typeof visual);
            console.log(`Visual name: ${visual.name}`);
            console.log(`Visual type: ${visual.type}`);
            console.log(`Visual title: ${visual.layout}`);
          });
        });
      })
      .catch((error) => {
        console.error("Error getting pages:", error);
      });
    // const pages = report.getPages();
    // Retrieve the page that contain the visual. For the sample report it will be the active page
    // let page = pages.filter(function (page) {
    //   return page.isActive;
    // })[0];
    // const visuals = page.getVisuals();
    // console.log("visuals", visuals);
    // console.log(
    //   visuals.map(function (visual) {
    //     return {
    //       name: visual.name,
    //       type: visual.type,
    //       title: visual.title,
    //       layout: visual.layout,
    //     };
    //   })
    // );
  }
  async function updateEmbedConfig(){
    await getUserData();
    const token = await getEmbedTokens();
    
      setPowerBIEmbedView(
        <PowerBIEmbed
          embedConfig={{
            type: "qna", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            datasetIds: [pbiResource.resourceId],
            embedUrl: pbiResource.details.qnaEmbedURL,
            accessToken: token,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            question: general?.qnaQuery,
          }}
          cssClassName={"widgetEmbededClass h-100"}
        />
      );
    

  }
  useEffect(() => {
    setPowerBIEmbedView(false)
    let ignore = false;

    
    updateEmbedConfig();
    

    return () => {
      ignore = true;
    };
  }, [general?.qnaQuery]);
  // const handleQnaSearch = async () => {
  //   // Get a reference to the embedded Q&A HTML element.
  //   const qnaContainer = document.getElementById("qnaContainer");

  //   // Get a reference to the embedded Q&A.
  //   const qna = window.powerbi.get(qnaContainer);

  //   // Change the displayed question.
  //   await qna.setQuestion("This year sales");
  // };

  return (
    <div className="card-body h-100">{powerBIEmbedView && powerBIEmbedView}</div>
  );
}

export default WidgetEmbededView;
