import { routes, get, post, pbiGet, pbiPost  } from "./index";

export const getEmbedToken = (clientId,body, user) => {
  return pbiPost(
    `${routes.getEmbedToken+'?clientId='+clientId}`,
    { body, user }
  );
};

export const searchPbiResources = (workspaceId, clientId, user) => {
  return get(
    `${'/admin/client/'+clientId+'/pbiResources/search?workspaceId='+workspaceId}`,
    { user }
  );
};
export const getResourceEmbedToken = (resourceId, user) => {
  console.log(user);
  return get(
    `${'/exp/client/pbiResources/'+resourceId+'/embedToken'}`,
    { user }
  );
};
export const getPbiResources = (resourceId, clientId, user) => {
  return get(
    `${'/admin/client/'+clientId+'/pbiResources/'+resourceId}`,
    { user }
  );
};