import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { getClientWorkspace } from "../../../services";
import { updateWorkspaceData } from "../../../services/clientAdmin";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientWorkspaceUpdate({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  dispatch(setActiveMenu({ activeMenu: "activity log" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

//   async function startFetching() {
//     let user = false;

//     setShowLoader(true);
//     if (!userData) {
//       let pbUser = await getUserData();
//       user = {
//         token: pbUser.accessToken,
//       };
//       setSearchValue(pbUser.userId);
//     } else {
//       user = {
//         token: userData.accessToken,
//       };
//       setSearchValue(userData.userId);
//     }
//     console.log(searchValue);
//     setAlertMessage(localStorage.getItem("alertMessage"));
//     localStorage.setItem("alertMessage", "");
//     let ignore = false;
//     const response = await getClientWorkspace(
//       workspaceId,
//       clientId,
//       user
//     ).catch((error) => {
//       if (error.code && error.code == "ERR_NETWORK") {
//         // The request was made and the server responded with a status code
//         // that falls out of the range of 2xx
//         //setError(error.response.data.message);
//         ignore = true;
//         setShowLoader(false);
//         navigate("/error-page");
//       } else if (error.request) {
//         ignore = true;
//         // The request was made but no response was received
//         setError("No response received from the server.");
//         setShowLoader(false);
//       } else {
//         ignore = true;
//         // Something happened in setting up the request that triggered an error
//         setError("An error occurred while processing the request.");
//         setShowLoader(false);
//       }
//     });

//     if (!ignore) {
//       setData(response.data);
//       setShowLoader(false);
//     }
//   }
  const clearSearch = () => {
    setSearchValue("");
    //startFetching();
  };
  const updateWorkspace = (params) => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    updateWorkspaceData(workspaceId, params, user)
      .then((res) => {
        let succesMessage = "Client workspace created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client/workspaces");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    //startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <main className="content">
            <div className="container-fluid p-0">
              <h1 className="h3 mb-3">Workspace Update</h1>
              {alertMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-message">{alertMessage}</div>
                </div>
              )}

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header border-bottom">
                      <h5 className="card-title mb-0">Workspace Update</h5>
                      <div>
                        <div className="d-flex flex-wrap"></div>
                      </div>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{
                          nameAlias: data.nameAlias,
                          description: data.description,
                          status: true,
                        }}
                        onSubmit={async (values) => {
                          await new Promise((r) => setTimeout(r, 500));
                          updateWorkspace(JSON.stringify(values, null, 2));
                        }}
                      >
                        <Form>
                          <div className="col-md-12">
                            <label className="form-label">Name Alias</label>
                            <Field
                              type="text"
                              name="nameAlias"
                              className="form-control"
                            />
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Description</label>
                            <Field
                              as="textarea"
                              name="description"
                              className="form-control"
                            />
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>                          

                          <div className="col-md-12">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                            <Link
                              className="btn btn-secondary"
                              to={`/client/workspaces`}
                            >
                              Cancel
                            </Link>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientWorkspaceUpdate;
