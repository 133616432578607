import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setClientGroups } from "../../../store/features/generalSlice";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import PageAccordion from "./PageAccordion";

import Eye from "../../../assets/theme/images/eye.png";
import Export from "../../../assets/theme/images/export.png";

import {
  Info,
  Pin,
  Edit,
  TrashTwo,
  ExportIcn,
  ReportOne,
  LoaderAcc,
  Reports,
} from "../../../assets/theme/images/icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ResourceInfoModal({ show, handleClose, modalData }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Resource Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-inner">
          <div className="form-row">
            <span className="label">Name: </span>
            {modalData.aliasName ? modalData.aliasName : modalData.name}
          </div>
          <div className="form-row">
            <span className="label">Description: </span>
            {modalData.description ? modalData.description : "NA"}
          </div>
          <div className="form-row">
            <span className="label">Status: </span>
            {modalData.status ? modalData.status : "NA"}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-1 btn-bg-2" variant="secondary" onClick={handleClose}>
          Close
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
}

export default ResourceInfoModal;
