import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";


import BurggerMenu from "../../assets/theme/images/burgger-menu.png";
import BurggerMenuWhite from "../../assets/theme/images/burgger-menu-white.png";
import { useSelector, useDispatch } from "react-redux";
import { setBurggerMenuOpenClose, setUserData } from "../../store/features/generalSlice";
import { setIdToken } from "../../store/features/userSlice";

import { NotiIcon } from "../../assets/theme/images/icons";
import LoggedUser from "../../assets/theme/images/dp.png";
import arrowUser from "../../assets/theme/images/arrow-1.png";
import MainSearch from "../../assets/theme/images/search.png";

import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
  useIsAuthenticated,
} from "@azure/msal-react";

function Navbar() {
  const [showProfileMenu, setShowProfileMenu] = useState("");
  const navigate = useNavigate();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);
  const handleLogout = () => {
    instance.logout();
    localStorage.removeItem("msalUser");
    localStorage.removeItem("pbUser");
    dispatch(setUserData({ userData: false }));
    dispatch(setIdToken({ idToken: false }));
    navigate("/");
  };
  function burggerMenuToggle() {
    let temp = general?.burggerMenuOpenClose === false ? true : false;
    dispatch(setBurggerMenuOpenClose({ burggerMenuOpenClose: temp }));
    console.log("burggerMenuOpenClose", general?.burggerMenuOpenClose);
  }
  function handleShowProfileMenu() {
    let temp = showProfileMenu === false ? true : false;
    setShowProfileMenu(temp);
  }

  return (
    <div className="top">
      <div className="cell-one">
        <div className="search">
          <img src={MainSearch} alt="" />
          <input placeholder="Search Projects" type="text" />
        </div>
        <button
          className="open-close mobBurggerButton"
          onClick={() => burggerMenuToggle()}
        >
          <img src={BurggerMenuWhite} alt="" />
        </button>
        <ul className="menu">
          <li className="active">
            <a href="#">Dashboard</a>
          </li>
          <li>
            <a href="#">Resources</a>
          </li>
          <li>
            <a href="#">Notifications</a>
          </li>
        </ul>

        <select name="" id="" className="nice-select">
          <option value="">Dashboard</option>
          <option value="">Resources</option>
          <option value="">Notifications</option>
        </select>
      </div>
      <div className="cell-two">
        <a href="#" className="noti active">
          <NotiIcon />
        </a>
        <div className="loginUser">
          
            <img src={LoggedUser} alt="" onClick={() => handleShowProfileMenu()} />
            <span onClick={() => handleShowProfileMenu()}>{general?.userData.email}</span>
            <img
              className="arrow"
              src={arrowUser}
              alt=""
              onClick={() => handleShowProfileMenu()}
              
            />
          
          {/* <ul className="dropdown-menu show"> */}
          <ul className={showProfileMenu ? "dropdown-menu show" : "dropdown-menu"} >
            <li className="name">{general?.userData.email}</li>
            <li>
              <a class="dropdown-item" href="#">
                Profile
              </a>
            </li>

            <li>
              <a class="dropdown-item" href="#">
                Settings
              </a>
            </li>
            <li>
              <a class="dropdown-item" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="dropdown">
          <a
            className="btn btn-secondary dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={LoggedUser} alt="" /> John Due
          </a>

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a className="dropdown-item" href="#">
                Profile
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Settings
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Logout
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
}

export default Navbar;
