import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  createClientSubscription,
  searchPbiReports,
  searchClients,
  getClientWorkspace,
  searchClientWorkspaces,
} from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientSubscriptionCreate({ instance }) {
  const { clientId } = useParams();
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [emailCount, setEmailCount] = useState([""]);
  const [showLoader, setShowLoader] = useState(true);
  const [defaultValues, setDefaultValues] = useState({
    name: "Personal Subscription2",
    resourceId: "a8665509-811e-472a-3767-08dc0ab9fa34",
    subscriptionType: "Personal",
    resourceType: "Report",
    emailIds: "", //["prasanthvadakkel@yopmail.com"],
    distributionList: "",
    mobileNumbers: "", //["919946920842"],
    fileFormat: 1,
    roles: "Brand",
  });
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [reports, setReports] = useState(false);
  const [clients, setClients] = useState(false);
  const [clientWorkspaces, setClientWorkspaces] = useState(false);

  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  const saveClientSubscription = (params) => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    createClientSubscription(clientId, params, user)
      .then((res) => {
        let succesMessage = "Client subscription created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-subscriptions/" + clientId);
      })
      .catch((error) => {
        let tempValue = JSON.parse(params);
        if (tempValue.subscriptionType === "Personal") {
          tempValue.emailIds = tempValue.emailIds.split(",");
          tempValue.mobileNumbers = tempValue.mobileNumbers.split(",");
        }
        setDefaultValues(tempValue);
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };
  async function getReports(clientId, workspaceId) {
    let user = false;
    //setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchPbiReports(clientId, workspaceId, user);

    const results = [];
    // Store results in the results array
    response.data.pbiResources.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setReports(results);
  }

  async function getClients() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClients(searchValue, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClients(results);
  }
  async function getClientWorkspaces(clientId) {
    let user = false;
    //setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClientWorkspaces(clientId, user);

    const results = [];
    // Store results in the results array
    response.data.workspaces.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientWorkspaces(results);
  }

  function addEmailField() {
    setEmailCount([...emailCount, ""]);
  }

  function removeEmailField(index) {
    setEmailCount(emailCount.filter((field, i) => i !== index));
  }
  useEffect(() => {
    getUserData();
    getClients();
    setShowLoader(false);
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          
            <div className="inner client-creation-pg clearfix">
              <div className="title-top-wraper2">
                <h1>Client Subscription Creation</h1>
              </div>
              {alertMessage && (
                <AlertMessage
                  alertMessage={alertMessage}
                  alertType={"success"}
                />
              )}
              {error && (
                <AlertMessage alertMessage={error} alertType={"error"} />
              )}
              <div className="main-bg-wraper--2">
                <Formik
                  initialValues={defaultValues}
                  onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));

                    if (values.subscriptionType === "Personal") {
                      let email = values.emailIds.toString();
                      let mobileNumbers = values.mobileNumbers.toString();
                      values.mobileNumbers = mobileNumbers;
                      values.emailIds = email;
                      values.distributionList = null;
                    }
                    if (values.subscriptionType === "Distribution") {
                      values.distributionList = values.emailIds;
                    }

                    saveClientSubscription(JSON.stringify(values, null, 2));
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="details-list-wraper">
                        <div className="details-list-inner">
                          <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                              <div className="form-style-1">
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Subscription Type
                                    </label>
                                    <div className="col-md-9">
                                      <Field
                                        name="subscriptionType"
                                        className="form-control"
                                        as="select"
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setFieldValue(
                                            "subscriptionType",
                                            selectedValue
                                          );
                                        }}
                                      >
                                        <option key="" value="">
                                          Select Subscription Type
                                        </option>
                                        <option key="Personal" value="Personal">
                                          Personal
                                        </option>
                                        <option
                                          key="Distribution"
                                          value="Distribution"
                                        >
                                          Distribution
                                        </option>
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Name
                                    </label>
                                    <div className="col-md-9">
                                      <Field
                                        type="text"
                                        name="name"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Client
                                    </label>
                                    <div className="col-md-9">
                                      {clients && (
                                        <Field
                                          name="clientId"
                                          className="form-control"
                                          as="select"
                                          onChange={(e) => {
                                            const selectedValue =
                                              e.target.value;
                                            setFieldValue(
                                              "clientId",
                                              selectedValue
                                            );
                                            getClientWorkspaces(selectedValue);
                                          }}
                                        >
                                          <option key="" value="">
                                            Select Client
                                          </option>
                                          {clients.map((option) => {
                                            return (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.key}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                      )}
                                     
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Workspaces
                                    </label>
                                    <div className="col-md-9">
                                      {clientWorkspaces && (
                                        <Field
                                          name="workspaceId"
                                          className="form-control"
                                          as="select"
                                          onChange={(e) => {
                                            const selectedValue =
                                              e.target.value;
                                            setFieldValue(
                                              "workspaceId",
                                              selectedValue
                                            );
                                            getReports(values.clientId, selectedValue);
                                          }}
                                        >
                                          <option key="" value="">
                                            Select Workspaces
                                          </option>
                                          {clientWorkspaces.map((option) => {
                                            return (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.key}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Reports
                                    </label>
                                    <div className="col-md-9">
                                      {reports && (
                                        <Field
                                          name="resourceId"
                                          className="form-control"
                                          as="select"
                                          onChange={(e) => {
                                            const selectedValue =
                                              e.target.value;
                                            setFieldValue(
                                              "resourceId",
                                              selectedValue
                                            );
                                          }}
                                        >
                                          <option key="" value="">
                                            Select Report
                                          </option>
                                          {reports.map((option) => {
                                            return (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.key}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {values.subscriptionType === "Personal" && (
                                  <div>
                                    {emailCount.map((value, index) => (
                                      <div key={index}>
                                        <div className="mb-4">
                                          <div className="row align-items-center">
                                            <label
                                              for=""
                                              className="col-md-3 col-form-label"
                                            >
                                              Emails
                                            </label>
                                            <div className="col-md-7">
                                              <Field
                                                type="text"
                                                name={`emailIds[${index}]`}
                                                className="form-control"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-4">
                                          <div className="row align-items-center">
                                            <label
                                              for=""
                                              className="col-md-3 col-form-label"
                                            >
                                              Mobile Numbers
                                            </label>
                                            <div className="col-md-7">
                                              <Field
                                                type="text"
                                                name={`mobileNumbers[${index}]`}
                                                className="form-control"
                                              />
                                            </div>
                                            {index > 0 && (
                                              <div className="col-md-2">
                                                <a
                                                  className="btn btn-danger  mb-1 ms-1"
                                                  onClick={() =>
                                                    removeEmailField(index)
                                                  }
                                                >
                                                  Remove
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="row align-items-center">
                                      <label
                                        for=""
                                        className="col-md-3 col-form-label"
                                      ></label>
                                      <div className="col-md-9">
                                        <a
                                          className="btn btn-primary  mb-1 ms-1"
                                          onClick={() => addEmailField()}
                                        >
                                          Add Field
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {values.subscriptionType === "Distribution" && (
                                  <div>
                                    <div className="mb-4">
                                      <div className="row align-items-center">
                                        <label
                                          for=""
                                          className="col-md-3 col-form-label"
                                        >
                                          Emails
                                        </label>
                                        <div className="col-md-7">
                                          <Field
                                            type="text"
                                            name="emailIds"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <div className="row align-items-center">
                                        <label
                                          for=""
                                          className="col-md-3 col-form-label"
                                        >
                                          Mobile Numbers
                                        </label>
                                        <div className="col-md-7">
                                          <Field
                                            type="text"
                                            name="mobileNumbers"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Roles
                                    </label>
                                    <div className="col-md-7">
                                      <Field
                                        type="text"
                                        name="roles"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btm-btns-row">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="btngroup2">
                              <Link
                                className="button-new1 grey"
                                to={`/client-subscriptions/${clientId}`}
                              >
                                Cancel
                              </Link>
                              <button className="grad-btn-1">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientSubscriptionCreate;
