import {createSlice} from '@reduxjs/toolkit';
const initialState = {    
    idToken:false,
    idTokenExp:false,
  }
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIdToken(state, action) {
            state.idToken = action.payload.idToken;
          },
        setIdTokenExp(state, action) {
            state.idTokenExp = action.payload.idTokenExp;
          },
    }
});
// Action creators are generated for each case reducer function
export const {     
    setIdToken,
    setIdTokenExp,
 } = userSlice.actions
export default userSlice.reducer;