import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import { searchClients, pbiResourcesRefresh } from "../../../services";
import { Link, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import MyFavourites from "./MyFavourites";
import ClientAccordion from "./ClientAccordion";
import SearchBlack from '../../../assets/theme/images/search-black.png';

function Dashboard({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <div className="inner clearfix">
          <h1>Dashboard</h1>

          <div className="top-slider clearfix">
            <MyFavourites /> 
          </div>
          <div className="clients-sec">
            <div className="clients-top">
              <h2>Clients</h2>
              <div className="filter-cell">
                <div className="search">
                  <img src={SearchBlack} alt="" />
                  <input placeholder="Search Projects" type="text" />
                </div>
                <select name="" id="" className="nice-select">
                  <option value="">Filter</option>
                  <option value="">Filter</option>
                  <option value="">Filter</option>
                </select>
              </div>
            </div>

            <ClientAccordion />
          </div>
        </div>
      </MainLayout>
    </MsalProvider>
  );
}

export default Dashboard;
