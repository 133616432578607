import { useState, useEffect, useId } from "react";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { loginRequest } from "../utils/authConfig"; // Import your MSAL configuration

import AidiLogo from "../assets/theme/images/powered-logo.png";
import Logo from "../assets/theme/images/logo.png";
import LeftLogo from "../assets/theme/images/left-logo.jpg";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import {
  manageDeviceToken,
  createActivityLog,
  getNameandLogo,
} from "../services/user";

import { getWebsiteNameLogo, login, getEmbedToken } from "../services";
import { useSelector, useDispatch } from "react-redux";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  setLogoImage,
  setBannerImage,
} from "../store/features/generalSlice";
import { setIdToken, setIdTokenExp } from "../store/features/userSlice";

const MsalLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  const uniqueID = uuidv4();
  //const activityLoader = useSelector((state) => state.general.activityLoader);
  const userData = useSelector((state) => state.general.userData);
  const userSlice = useSelector((state) => state.user);
  const general = useSelector((state) => state.general);
  const { instance, inProgress, accounts } = useMsal();
  console.log(accounts);
  const request = {
    scopes: ["openid"],
    account: accounts[0],
  };
  instance
    .acquireTokenSilent(request)
    .then((response) => {
      setIdToken(response.idToken);
    })
    .catch((error) => {
      console.log(error);
      // acquireTokenSilent can fail for a number of reasons, fallback to interaction
      // if (error instanceof InteractionRequiredAuthError) {
      //   instance.acquireTokenPopup(request).then((response) => {
      //     setIdToken(response.idToken);
      //   });
      // }
    });
  const { isAuthenticated } = useMsal();
  const handleLogin = (e) => {
    e.preventDefault();
    loginRequest.loginHint = email;
    instance.loginPopup(loginRequest).catch((error) => {
      console.error("Login error: ", error);
      setError("Login error: ", error);
    });
  };

  const handleLogout = () => {
    instance.logout();
  };

  async function getIdToken() {
    let retn;
    const request = {
      scopes: ["openid"],
      account: accounts[0],
    };

    await instance
      .acquireTokenSilent(request)
      .then((response) => {
        retn = response.idToken;
        dispatch(setIdToken({ idToken: response.idToken }));
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        // if (error instanceof InteractionRequiredAuthError) {
        //   instance.acquireTokenPopup(request).then((response) => {
        //     setIdToken(response.idToken);
        //   });
        // }
      });
    return retn;
  }

  async function loginFunction() {
    const account = accounts[0];
    localStorage.setItem("msalUser", JSON.stringify(account));
    const currentTime = Date.now();
    console.log(currentTime);
    // if(currentTime) {
    //dispatch(setIdToken({ idToken: false }));

    let msalToken =
      !userSlice.idToken && userSlice.idTokenExp < currentTime
        ? await getIdToken()
        : userSlice.idToken;
    // Accessing user information
    const params = {
      idToken: msalToken,
      email: account.username,
      domain: "vittreebi.com",
      tenantId: account.tenantId,
      clientId: account.idTokenClaims.aud,
      groups: account.idTokenClaims.groups,
    };
    await login(params)
      .then((res) => {
        const user = res.data;
        dispatch(setUserData({ userData: user }));
        localStorage.setItem("pbUser", JSON.stringify(user));
        localStorage.setItem("sessionId", user.userId + "::" + uniqueID);
        setIsLoggedIn(true);
        let deviceToken = localStorage.getItem("deviceToken");
        let paramsData = {
          deviceToken: deviceToken,
          deviceType: "Mobile",
          action: "Add",
        };
        const userToken = {
          token: user.accessToken,
        };

        manageDeviceToken(paramsData, userToken)
          .then((res) => {
            console.log("success");
          })
          .catch((error) => {
            console.log("error", error);
          });
        addActivityLog("Logged in successfully", userToken);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.data.message;
          setError("Login error: " + errorMessage);
          setIsLoggedIn(false);
        } else {
          setError("Login error: ");
        }
      });
  }
  async function addActivityLog(log, userToken) {
    const message =
      log +
      " : " +
      `${browserName} ${browserVersion} on ${osName} ${osVersion}`;
    const extra = log;
    let formData = {
      activity: log,
      message: message,
      extra: extra,
    };

    createActivityLog(formData, userToken)
      .then((res) => {
        console.log("general?.powerBIEmbedLayoutType");
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function getDomainData(hostingDomain) {
    getNameandLogo(hostingDomain)
      .then((res) => {
        
        dispatch(setLogoImage({ logoImage: res.data.logo }));
        dispatch(setBannerImage({ bannerImage: res.data.banner }));
      })
      .catch((e) => {
        if (e?.response) {
          dispatch(setLogoImage({ logoImage: Logo }));
          dispatch(setBannerImage({ bannerImage: LeftLogo }));
          let errorMessage = e.response.message;
          console.log(errorMessage);
        }
      });
  }

  useEffect(() => {
    if (accounts.length > 0) {
      if (!isLoggedIn) {
        loginFunction();
      }
      if (isLoggedIn) {
        if (userData.role === "SuperAdmin") {
          //navigate("/client/dashboard");
          navigate("/dashboard-new");
        } else if (userData.role === "ClientAdmin") {
          navigate("/client/dashboard");
        } else if (userData.role === "User") {
          navigate("/user/dashboard");
        } else {
          navigate("/client/dashboard");
        }
      }
    }
    let hostname = window.location.hostname;

    console.log("hostname", hostname.split(".")[0]);
    getDomainData(hostname.split(".")[0]);
  }, [accounts, isLoggedIn]);

  return (
    <section className="login-section">
      <div className="container-fluid h-100 px-0">
        <div className="row g-0 h-100">
          <div className="col-xl-8 col-lg-7 col-md-6 h-100 d-none d-md-block">
            <div className="logsec-left">
              <img src={general?.bannerImage ? general?.bannerImage : ''} alt="" />
            </div>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-6 h-100">
            <div className="logsec-right">
              <div className="lsr-inner">
                <div className="lsr-logo">
                  <img src={general?.logoImage ? general?.logoImage : ''} alt="" />
                </div>

                <form className="form-style-1">
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      <div className="alert-message">{error}</div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="">
                          <i className="fa-regular fa-envelope"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Email ID"
                          email="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <AuthenticatedTemplate>
                        <button
                          onClick={handleLogout}
                          className="btn btn-style-1 w-100"
                        >
                          Logout
                        </button>
                      </AuthenticatedTemplate>
                      <UnauthenticatedTemplate>
                        <button
                          onClick={handleLogin}
                          className="btn btn-style-1 w-100"
                        >
                          Signin Using Microsoft
                        </button>
                      </UnauthenticatedTemplate>
                    </div>
                  </div>
                </form>

                <div className="poweredby">
                  <p>Powered by</p>
                  <img src={AidiLogo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MsalLogin;
