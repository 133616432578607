import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClientGroups,
  setClientPbiResources,
  setWholePbiResources,
  setActivityLoader,
} from "../../../store/features/generalSlice";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import AllReportAccordion from "./AllReportAccordion";
import AllDashboardAccordion from "./AllDashboardAccordion";
import AllDatasetAccordion from "./AllDatasetAccordion";
import AccordionArrow from "../../../assets/theme/images/down-arrow.svg";
import { searchPbiResources } from "../../../services/user";
import { LoaderAcc, ArrowAccRight } from "../../../assets/theme/images/icons";

function CustomToggle({ children, eventKey }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");

  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!", eventKey)
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
function AllResourcesAccordion() {
  const general = useSelector((state) => state.general);
  return (
    <Accordion defaultActiveKey="report" className="clients-accordion-main">
      {!general?.activityLoader && Object.keys(general.typewiseResources).length > 0 && (
        <>
          <Card key="report">
            <Card.Header>
              <CustomToggle eventKey="report">
                Report
                <ArrowAccRight className="accArrowIcon" />
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="report">
              <Card.Body>
                <div className="clients-tab-inner">
                  <AllReportAccordion />
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card key="dashboard">
            <Card.Header>
              <CustomToggle eventKey="dashboard">
                Dashboard
                <ArrowAccRight className="accArrowIcon" />
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="dashboard">
              <Card.Body>
                <div className="clients-tab-inner">
                  <AllDashboardAccordion />
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card key="dataset">
            <Card.Header>
              <CustomToggle eventKey="dataset">
                Dataset
                <ArrowAccRight className="accArrowIcon" />
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="dataset">
              <Card.Body>
                <div className="clients-tab-inner">
                  <AllDatasetAccordion />
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </>
      )}

      {general?.activityLoader && (
        <Card>
          <Card.Body className="page-accordion-wrap">
            <div className="acc-loader">
              <LoaderAcc />
            </div>
          </Card.Body>
        </Card>
      )}
      
      {!general?.activityLoader && (!general?.typewiseResources || Object.keys(general.typewiseResources).length <= 0) && (
        <Card>
          <Card.Body className="page-accordion-wrap">
            No Result found!
          </Card.Body>
        </Card>
      )}
    </Accordion>
  );
}

export default AllResourcesAccordion;
