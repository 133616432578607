const BASE_URL = "https://dev-aidi-client-wa.azurewebsites.net/v1/api";
const EXP_URL = process.env.REACT_APP_API_URL;
const PBI_RESOURCE_URL = "https://dev-aidi-pbiresource-wa.azurewebsites.net/v1/api";
const MOBILE_TOKEN = ""; //DeviceInfo.getUniqueId()
const APPLICATION = "AIDI";
const FIREBACE_VAPIDKEY = 'BHYOGFPJNOldVykzsfok-S1QbYnnpBaH0BH2kJOODulX0iYtalPwqBIdaLCum3OjZ41LntTuxEFhniDJXuJidxE';

const contentTypes = {
  json: "application/json",
  mfd: "multipart/form-data",
};

export { BASE_URL, EXP_URL, PBI_RESOURCE_URL, MOBILE_TOKEN, APPLICATION, contentTypes, FIREBACE_VAPIDKEY};