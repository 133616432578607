import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { createClientUser } from "../../../services/clientAdmin";
import { useNavigate, useParams, Link } from "react-router-dom";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientUserCreate({ instance }) {
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.general.userData);
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "client user" }));
  const saveClientUser = (params) => {
    const user = {
      token: accessToken,
    };
    createClientUser(params, user)
      .then((res) => {
        let succesMessage = "Client user created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client/users-list");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };
  async function getUserData() {
    let pbUser = false;
    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <main className="content">
          <div className="container-fluid p-0">
            {error && (
              <div className="alert alert-danger" role="alert">
                <div className="alert-message">{error}</div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header border-bottom">
                    <h5 className="card-title mb-0">Client user Creation</h5>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        name: "Digiora  User",
                        email: "digiora@dapasol.com",
                        role: "User",
                      }}
                      onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        saveClientUser(JSON.stringify(values, null, 2));
                      }}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <div className="col-md-12">
                            <label className="form-label">Name</label>
                            <Field
                              type="text"
                              name="name"
                              className="form-control"
                            />
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Email</label>
                            <Field
                              type="text"
                              name="email"
                              className="form-control"
                            />
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Role</label>
                            <Field
                              name="role"
                              className="form-control"
                              as="select"
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                setFieldValue("role", selectedValue);
                              }}
                            >
                              <option key="" value="">
                                Select Role
                              </option>                              
                              <option key="ClientAdmin" value="ClientAdmin">
                                ClientAdmin
                              </option>
                              <option key="User" value="User">
                                User
                              </option>
                            </Field>
                            <div className="form-text"></div>
                            <div id="" className="invalid-feedback"></div>
                          </div>

                          <div className="col-md-12">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                            <Link
                              className="btn btn-secondary"
                              to={`/client/users-list`}
                            >
                              Cancel
                            </Link>
                          </div>
                        </Form>
                      )}  
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientUserCreate;
