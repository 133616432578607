import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setClientReportPages } from "../../../store/features/generalSlice";
import { GetReportPages } from "../../../services";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import VisualsAccordion from "./VisualsAccordion";

import Eye from "../../../assets/theme/images/eye.png";
import Export from "../../../assets/theme/images/export.png";
import {
  Info,
  Pin,
  Edit,
  TrashTwo
} from '../../../assets/theme/images/icons';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function TileAccordion() {
  const general = useSelector((state) => state.general);
  return (
    <Accordion defaultActiveKey="">
      {general?.clientDashboardTiles.length > 0 &&
        general?.clientDashboardTiles.map((item) => (
          <Card key={item.id}>
            <Card.Header>
              <CustomToggle eventKey="0">{item.name}</CustomToggle>
              <div className="accordion-actions">
                <span>{item.displayName}</span>
                <div className="buttons">
                  <a href="#">
                    <img src={Eye} alt="" /> View
                  </a>
                  <a href="#" className="export">
                    <img src={Export} alt="" /> Export
                  </a>
                </div>
                <div className="icons">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <Info />
                  </a>
                  <a href="#">
                    <Pin />
                  </a>
                  <a href="#">
                    <Edit />
                  </a>
                  <a href="#">
                    <TrashTwo />
                  </a>
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="visual-accordion-wrap">
                {/* <VisualsAccordion /> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
    </Accordion>
  );
}

export default TileAccordion;
