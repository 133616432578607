import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import {
  searchClients,
  getWebsiteNameLogo,
  getEmbedToken,
  getPbiResources,
} from "../../../services";
import { Link } from "react-router-dom";

import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
} from "../../../store/features/generalSlice";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";

function Qna({ instance }) {
  const navigate = useNavigate();
  const [report, setReport] = useState();
  const [user, setUser] = useState();
  const [embedToken, setEmbedToken] = useState("");
  const clientId = "5b50d8c1-9151-4cbe-fa12-08dbe67a40e2";

  function getEmbedTokens() {
    console.log("response", user);
    const body = {
        "datasets": [
          {
            "id": "ea039408-5b0a-418d-9950-0c1d582f2d72"
          }
        ],
        "identities": [
          {
            "username": "aidi_testemployee_1@dapasol.com",
            "roles": [
              "MD"
            ],
            "datasets": [
              "ea039408-5b0a-418d-9950-0c1d582f2d72"
            ]
          }
        ]
      };
    getEmbedToken(clientId, body, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(res.data);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  function getPbiResourceData() {
    getPbiResources({}, user)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function reportMobileLayout() {
    console.log("report maan", report);
    if (report) {
      report.setComponentTitle("Embedded Report");
      let pages = await report.getPages();
      let mobileLayout = pages[0].hasLayout(models.LayoutType.MobilePortrait);
      console.log("report maan");
    }
  }
  useEffect(() => {
    
    if (report) {
      report.setComponentTitle("Embedded Report");
      console.log("report maan");
    }
    let userData = localStorage.getItem("pbUser");
    let accessToken = "";
    if (userData) {
      userData = JSON.parse(userData);
      accessToken = userData.accessToken;
    } else {
      userData = null;
    }
    setUser({
      token: accessToken,
    });
    if (accessToken != "") {
      getEmbedTokens();
      //getPbiResources();
    }
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <PowerBIEmbed
          embedConfig={{
            type: "qna", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            datasetIds:["ea039408-5b0a-418d-9950-0c1d582f2d72"],
            embedUrl:
              "https://app.powerbi.com/qnaEmbed?config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFFuYUVtYmVkIjp0cnVlfX0%3d",
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                  reportMobileLayout();
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ]
              
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={async (embeddedReport) => {
            window.report = embeddedReport;
            setReport(embeddedReport);
            console.log("setReport", report);
          }}
        />
      </MainLayout>
    </MsalProvider>
  );
}

export default Qna;
