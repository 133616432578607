// ImagePopup.js
import React from "react";
import "../../assets/theme/css/ImagePopup.css";
import { Close2, Download2 } from "../../../src/assets/theme/images/icons";

const ImagePopup = ({ imageUrl, onClose }) => {
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content">
        <img src={imageUrl} alt="Popup" />
        <button className="popupButton" onClick={onClose}>
          <Close2 />
        </button>
        <button className="popupButton download">
          <a href={imageUrl}>
            <Download2 />
          </a>
        </button>
      </div>
    </div>
  );
};

export default ImagePopup;
