import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setClientReportPages } from "../../../store/features/generalSlice";
import { GetReportPages } from "../../../services";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import VisualsAccordion from "./VisualsAccordion";

import Eye from "../../../assets/theme/images/eye.png";
import Export from "../../../assets/theme/images/export.png";
import {
  Info,
  Pin,
  Edit,
  TrashTwo,
  ExportIcn,
  ReportOne,
  LoaderAcc,
  Reports,
} from "../../../assets/theme/images/icons";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function PageAccordion() {
  const general = useSelector((state) => state.general);
  return (
    <Accordion defaultActiveKey="">
      {general?.clientReportPages.length > 0 &&
        general?.clientReportPages.map((item) => (
          <Card>
            <Card.Header>
              <CustomToggle eventKey="0">
                <ReportOne />
                {item.displayName ? item.displayName : item.name}
              </CustomToggle>
              <div className="accordion-actions">
                <div className="buttons">
                  <a href="#">
                    <img src={Eye} alt="" /> View
                  </a>
                </div>
                <div className="icons">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <Info />
                  </a>
                  <a href="#">
                    <Pin />
                  </a>

                  <a href="#">
                    <ExportIcn />
                  </a>

                  {/* <a href="#">
                    <Edit />
                  </a>
                  <a href="#">
                    <TrashTwo />
                  </a> */}
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="visual-accordion-wrap">
                {/* <VisualsAccordion /> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
    </Accordion>
  );
}

export default PageAccordion;
