import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import {
  createClientNotification,
  getClient,
} from "../../../services/clientAdmin";
import {
  searchClients,
  searchClientUsers,
  searchClientSecurityGroups,
} from "../../../services";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import { Formik, Form, Field, ErrorMessage, Checkbox } from "formik";

function ClientNotificationsCreate({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [clients, setClients] = useState(false);
  const [clientUsers, setClientUsers] = useState(false);
  const [clientGroups, setClientGroups] = useState(false);
  const [audienceType, setAudienceType] = useState([
    { key: "user", value: "User" },
    { key: "security group", value: "Security Group" },
    { key: "client", value: "Client" },
  ]);
  const [audienceValue, setAudienceValue] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "manage notifications" }));

  async function createNotification(params) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    createClientNotification(params, user, "multipart/form-data")
      .then((res) => {
        let succesMessage = "Client notification created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-notifications");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          setShowLoader(false);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      });
  }
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function getClientUsers(clientId) {
    let user = false;
    //setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClientUsers(clientId, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientUsers(results);
    //setShowLoader(false);
  }
  async function getClientGroups(clinetId) {
    let user = false;

    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClientSecurityGroups(clinetId, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientGroups(results);
  }
  async function getClients() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClients(searchValue, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClients(results);
  }

  useEffect(() => {
    let ignore = false;

    getUserData();
    getClients();
    setShowLoader(false);
    return () => {
      ignore = true;
    };
  }, []);

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          
            <div className="inner client-creation-pg clearfix">
              <div className="title-top-wraper2">
                <h1>Notifications Create</h1>
              </div>
              {alertMessage && (
                <AlertMessage
                  alertMessage={alertMessage}
                  alertType={"success"}
                />
              )}
              {error && (
                <AlertMessage alertMessage={error} alertType={"error"} />
              )}
              <div className="main-bg-wraper--2">
                <Formik
                  initialValues={{
                    clientId: "",
                    type: "Internal",
                    audience: "Client",
                    audienceId: "565E5137-EE9D-4376-8C1F-08DBC14EE738",
                    subject: "Sample Subject",
                    content: "Sample Content",
                    deepLink: "client/client-notification-create/",
                    file: null,
                    createdBy: "3065c6a9-4b38-43bb-1dad-08dc01538598",
                  }}
                  onSubmit={async (values) => {
                    values.createdBy = userData.userId;
                    await new Promise((r) => setTimeout(r, 500));
                    const formData = new FormData();
                    formData.append("type", values.type);
                    formData.append("audience", values.audience);
                    formData.append("audienceId", values.audienceId);
                    formData.append("subject", values.subject);
                    formData.append("content", values.content);
                    formData.append("deepLink", values.deepLink);
                    formData.append("file", file);
                    formData.append("createdBy", values.createdBy);

                    createNotification(formData);
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form encType="multipart/form-data">
                      <div className="details-list-wraper">
                        <div className="details-list-inner">
                          <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                              <div className="form-style-1">
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Audience Type
                                    </label>
                                    <div className="col-md-9">
                                      <Field
                                        name="audience"
                                        className="form-control"
                                        as="select"
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setFieldValue(
                                            "audience",
                                            selectedValue
                                          );
                                          setAudienceValue(selectedValue);
                                        }}
                                      >
                                        <option key="" value="">
                                          Select Audience Type
                                        </option>
                                        {audienceType.map((option) => {
                                          return (
                                            <option
                                              key={option.key}
                                              value={option.key}
                                            >
                                              {option.value}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                                {audienceValue == "client" && (
                                  <div className="mb-4">
                                    <div className="row align-items-center">
                                      <label
                                        for=""
                                        className="col-md-3 col-form-label"
                                      >
                                        Client
                                      </label>
                                      <div className="col-md-9">
                                        {clients && (
                                          <Field
                                            name="audienceId"
                                            className="form-control"
                                            as="select"
                                            onChange={(e) => {
                                              const selectedValue =
                                                e.target.value;
                                              setFieldValue(
                                                "audienceId",
                                                selectedValue
                                              );
                                            }}
                                          >
                                            <option key="" value="">
                                              Select Client
                                            </option>
                                            {clients.map((option) => {
                                              return (
                                                <option
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.key}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {audienceValue == "user" && (
                                  <>
                                    <div className="mb-4">
                                      <div className="row align-items-center">
                                        <label
                                          for=""
                                          className="col-md-3 col-form-label"
                                        >
                                          Client
                                        </label>
                                        <div className="col-md-9">
                                          {clients && (
                                            <Field
                                              name="clientId"
                                              className="form-control"
                                              as="select"
                                              onChange={(e) => {
                                                const selectedValue =
                                                  e.target.value;
                                                setFieldValue(
                                                  "clientId",
                                                  selectedValue
                                                );
                                                getClientUsers(selectedValue);
                                              }}
                                            >
                                              <option key="" value="">
                                                Select Client
                                              </option>
                                              {clients.map((option) => {
                                                return (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.key}
                                                  </option>
                                                );
                                              })}
                                            </Field>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mb-4">
                                      <div className="row align-items-center">
                                        <label
                                          for=""
                                          className="col-md-3 col-form-label"
                                        >
                                          Users
                                        </label>
                                        <div className="col-md-9">
                                          {clientUsers && (
                                            <Field
                                              name="audienceId"
                                              className="form-control"
                                              as="select"
                                              onChange={(e) => {
                                                const selectedValue =
                                                  e.target.value;
                                                setFieldValue(
                                                  "audienceId",
                                                  selectedValue
                                                );
                                              }}
                                            >
                                              <option key="" value="">
                                                Select User
                                              </option>
                                              {clientUsers.map((option) => {
                                                return (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.key}
                                                  </option>
                                                );
                                              })}
                                            </Field>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {audienceValue == "security group" && (
                                  <>
                                    <div className="mb-4">
                                      <div className="row align-items-center">
                                        <label
                                          for=""
                                          className="col-md-3 col-form-label"
                                        >
                                          Client
                                        </label>
                                        <div className="col-md-9">
                                          {clients && (
                                            <Field
                                              name="clientId"
                                              className="form-control"
                                              as="select"
                                              onChange={(e) => {
                                                const selectedValue =
                                                  e.target.value;
                                                setFieldValue(
                                                  "clientId",
                                                  selectedValue
                                                );
                                                getClientGroups(selectedValue);
                                              }}
                                            >
                                              <option key="" value="">
                                                Select Client
                                              </option>
                                              {clients.map((option) => {
                                                return (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.key}
                                                  </option>
                                                );
                                              })}
                                            </Field>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <div className="row align-items-center">
                                        <label
                                          for=""
                                          className="col-md-3 col-form-label"
                                        >
                                          Security Group
                                        </label>
                                        <div className="col-md-9">
                                          {clientGroups && (
                                            <Field
                                              name="audienceId"
                                              className="form-control"
                                              as="select"
                                              onChange={(e) => {
                                                const selectedValue =
                                                  e.target.value;
                                                setFieldValue(
                                                  "audienceId",
                                                  selectedValue
                                                );
                                              }}
                                            >
                                              <option key="" value="">
                                                Select Security Group
                                              </option>
                                              {clientGroups.map((option) => {
                                                return (
                                                  <option
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.key}
                                                  </option>
                                                );
                                              })}
                                            </Field>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Subject
                                    </label>
                                    <div className="col-md-9">
                                      <Field
                                        type="text"
                                        name="subject"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      Content
                                    </label>
                                    <div className="col-md-9">
                                      <Field
                                        type="text"
                                        name="content"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="row align-items-center">
                                    <label
                                      for=""
                                      className="col-md-3 col-form-label"
                                    >
                                      File
                                    </label>
                                    <div className="col-md-9">
                                      <Field
                                        type="file"
                                        name="file"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btm-btns-row">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="btngroup2">
                              <Link
                                className="button-new1 grey"
                                to={`/client-notifications`}
                              >
                                Cancel
                              </Link>
                              <button className="grad-btn-1">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientNotificationsCreate;
