import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import ClientGroupTabs from "./ClientGroupTabs";
import AccordionArrow from '../../../assets/theme/images/down-arrow.svg';
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
function ClientAccordion() {
  console.log("Client Accordion");
  return (
    <Accordion defaultActiveKey="0" className="clients-accordion-main">

      <Card>
        <Card.Header>
          <CustomToggle eventKey="0">Client 1 <img src={AccordionArrow} alt="" /></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <ClientGroupTabs />
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <CustomToggle eventKey="1">Client 2 <img src={AccordionArrow} alt="" /></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>Hello! I'm another body</Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <CustomToggle eventKey="2">Client 3 <img src={AccordionArrow} alt="" /></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>Hello! I'm another body</Card.Body>
        </Accordion.Collapse>
      </Card>

    </Accordion>
  );
}

export default ClientAccordion;
