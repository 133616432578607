import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { searchClients, getWebsiteNameLogo } from "../../../services";
import { Link } from "react-router-dom";

import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
} from "../../../store/features/generalSlice";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

function EmbedPage() {
  return (
    <MainLayout>
      <div className="row">
        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header border-bottom d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Embed View</h5>
              
            </div>
            <div className="card-body">
              <div className="mb-3">
                
                <div className="row g-3 g-lg-4">
                <div className="row g-3 g-lg-4">
                <div className="col-lg-3 col-sm-6">
                    <label for="" className="form-label fw-bold">
                      Resource Type
                    </label>
                    <select className="nice-select">
                      <option selected>Select</option>
                      <option value="report">Report</option>
                      <option value="dashboard">Dashboard</option>
                      <option value="tile">Tile</option>
                      <option value="visual">Visual</option>
                      <option value="qna">Qna</option>
                      <option value="paginated report">Paginated Report</option>
                      <option value="create">Create</option>
                      
                    </select>
                    <div id="" className="invalid-feedback">
                    
                    </div>
                  </div>
                  </div>
                  <div className="row g-3 g-lg-4">
                  <div className="col-lg-3 col-sm-6">
                    <label for="" className="form-label fw-bold">
                    Resource ID
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      aria-describedby="emailHelp"
                    />
                    <div id="" className="invalid-feedback">
                      
                    </div>
                  </div>
                  </div>
                  <div className="row g-3 g-lg-4">
                  <div className="col-lg-3 col-sm-6">
                    <label for="" className="form-label fw-bold">
                    Embed Url
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      aria-describedby="emailHelp"
                    />
                    <div id="" className="invalid-feedback">
                      
                    </div>
                  </div>
                  </div>
                  <div className="row g-3 g-lg-4">
                  <div className="col-lg-3 col-sm-6">
                    <label for="" className="form-label fw-bold">
                    Embed Url
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      aria-describedby="emailHelp"
                    />
                    <div id="" className="invalid-feedback">
                      
                    </div>
                  </div>
                  </div>


                  
                  <div className="col-md-12">
                    <button className="btn btn-primary">Filter</button>                    
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "500px" }}>
              <PowerBIEmbed
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                  id: "a72efaa7-9128-4022-80b9-ee33c0c4d119",
                  embedUrl:
                    "https://app.powerbi.com/reportEmbed?reportId=a72efaa7-9128-4022-80b9-ee33c0c4d119&groupId=16912ff6-59e1-4a75-ab94-8cb6a33da6f5&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
                  accessToken:
                    "H4sIAAAAAAAEAB2Tt66EVgBE_-W1WCInSy7I4ZIzdMQlLbAs2fK_-9n1nGaOZv7-cfJ7nPPq588fygAdq9Qv-DHVp5TVb5u2mduIdGKtDEMSaObK4i3ijkoovab2uPc1FzjToii5AYgdraPHtqYVQXk8iGpbQMU4Eiav97H0t4ZiVYTdWw2OB_dFoaqU5424pUIy0VFdUjF03jrc_ZHLFjKs-_hOC2xsSbUDDz5EFEqmqZzSHi7cO8uaIPbX9vui_BiB3io9sotOGjc_M5C258W6yis-oR4XbendzPWwTuZpoH1TZYzNJDlYZltWlYkKanwxQmDgzItm8eos4sNiLTk6OXDNUXjQGtxru_ycU6oMftFUoqM6XNp_BROikg8YTU5kdGy7PsQuxDO4wBEoAZ-WFoNKWGyfL4SrrhabhvI6UXcxqJaDGGJQBDFBS2qmyiv1SYZcDBUBXhi3tWkbTKrRrLXck0axwXfCpN0UR-zia33OjPykBXbE312uzhzL3dJrCpq3biPxFXXz1Rml08Kh-imsXrZkfFAthC-RHAzXdl7ingvHucL4ukHuMzselDlzm4Y0MXShYLY5VddpRKcwitwo1tuCh9J5EpiTlA0kqjv4EEKopmJlj4cA2n1G5EuG4fNb6oCDfscnbKhVnXaSgSSDslqmsmNGU4N6Url5DEziictbZ7LPZ3Ba8_BUnY8yF9_wYjUz9cVPqqrILhkkChrDN_cJGbxAhtcdA_qLdgxcpPlBbg3B5W-Z6vE9c9nVjh0IbdCKVvWsyL5JYaPVYlMO05S3XAarbAgrJs9S81xZcFlb9HEr8_RZWRm4wB--V2sZiBVUlysC_6bjqRTNIn_yipjxV37m6CBJv5uGncrETbL4HKnVEenhBr6GqJQCyvmomIH9Lf7zx4-w3ss2g_r-vVP92-lcRvuB3Kyj3-hrqmx08i58FDyv9qlZhZSH3Ns-cKl58LbKWntmmD5Mee2mB6bjO2AxQknwUUspD3an5lflri4l0XLsPO2eiMsx5LETZiPtkDvohPY46ayiFzomNDidhMBVK-Javo_K69_OBaQaOlr3HJirSEdnweLZx3bfOIhwLZvNW2GCygnAUSNczA66q324CWjce4NSKu7uHhsRI-rkChTYAxVB21hPZNc811LbroNJHNb-lL9RzwmGacoOGXoakYRtEudzHPpBMuFTxTJdr4OSha_2Chvf_J5Q-CG6hp5fT4o-nzXFZouNZxkt-9eBTiS8s3rsdczl6afj_vXXf5rvpa1XLfq17L4wDu59yQvHjTIqy3Rp68X9T_nda8q3fa1_sQpJ-OM8xwqOLp0vOPDxu7wZB4jBbYgu896cc_IpIfpVlqncDhjvrCHkxZSIxOgtR4olva1amsyYqPZHSm9Xav2E5zn-eu6WUtNGBayVjDsqLWqebMnUqJEF2dtBM8OeeZpeNhM0IE4RSjijyG94IjchZdcXO_LWA9ZkFbiiPnyMmCC77U8OLTLOzDxBx7B9CCNnuAR7OAA0Rx-K6MFXND97yRrjINEAKKKNrvj-dli82JfLLd-BMDf0akB2Nu-KYmvxMUI-YQ-3Z_AIZOmuV4wxzVZkZXvhBptpsVgH_sU6_qk0WF94Fl6V_RRRcJCeOxVt2QsKT1vXMtOOmwpLXin_af7nX7Av4T9CBgAA.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNjk5NDUxMzYxLCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0=",
                  tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                    },
                    background: models.BackgroundType.Transparent,
                  },
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      function () {
                        console.log("Report loaded");
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log(event.detail);
                      },
                    ],
                    ["visualClicked", () => console.log("visual clicked")],
                    ["pageChanged", (event) => console.log(event)],
                  ])
                }
                cssClassName={"reportClass"}
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default EmbedPage;
