import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import BurggerMenu from "../../assets/theme/images/burgger-menu.png";
import BurggerMenuWhite from "../../assets/theme/images/burgger-menu-white.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setBurggerMenuOpenClose,
  setUserData,
} from "../../store/features/generalSlice";
import {
  getMyNotifications,
  createActivityLog,
  deleteDeviceToken,
} from "../../services/user";

import { setIdToken } from "../../store/features/userSlice";

import { NotiIcon } from "../../assets/theme/images/icons";
import UserAvatar from "../../assets/theme/images/user-avatar.png";
import arrowUser from "../../assets/theme/images/arrow-1.png";

import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
  useIsAuthenticated,
} from "@azure/msal-react";

function Navbar() {
  const [showProfileMenu, setShowProfileMenu] = useState("");
  const userData = useSelector((state) => state.general.userData);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);
  const handleLogout = async () => {
    
    localStorage.removeItem("msalUser");
    localStorage.removeItem("pbUser");
    dispatch(setUserData({ userData: false }));
    dispatch(setIdToken({ idToken: false }));
    await addActivityLog("Logout successfully done");
    await removeDeviceToken();
    instance.logout();
    navigate("/");
  };
  function burggerMenuToggle() {
    let temp = general?.burggerMenuOpenClose === false ? true : false;
    dispatch(setBurggerMenuOpenClose({ burggerMenuOpenClose: temp }));
    console.log("burggerMenuOpenClose", general?.burggerMenuOpenClose);
  }
  function handleShowProfileMenu() {
    console.log("handleShowProfileMenu");
    let temp = !showProfileMenu;
    setShowProfileMenu(temp);
  }

  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function startFetching() {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await getMyNotifications("", user).catch((error) => {});
    setData(response.data);
  }
  async function addActivityLog(log) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    const message = log;
    const extra = log;
    let formData = {
      activity: log,
      message: message,
      extra: extra,
    };

    createActivityLog(formData, user)
      .then((res) => {
        console.log("general?.powerBIEmbedLayoutType");
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function removeDeviceToken(log) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let deviceToken = localStorage.getItem("deviceToken");
    let paramsData = {
      deviceToken: deviceToken,
      deviceType: "Mobile",
      action: "Add",
    };

    deleteDeviceToken(paramsData, user)
      .then((res) => {
        console.log("success");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    getUserData();
    startFetching();
  }, []);
  return (
    <div className="top">
      <div className="cell-one">
        <button
          className="open-close mobBurggerButton"
          onClick={() => burggerMenuToggle()}
        >
          <img src={BurggerMenuWhite} alt="" />
        </button>
        <ul className="menu">
          <li className={general?.activeMenu == "dashboard" ? "active" : ""}>
            <a href="/client/dashboard">Dashboard</a>
          </li>
        </ul>

        <select name="" id="" className="nice-select">
          <option value="">Dashboard</option>
        </select>
      </div>
      <div className="cell-two">
        <a href="/client/my-notifications" className="noti">
          <div className="count">{data?.unReadCount}</div>
          <NotiIcon />
        </a>
        <div className="loginUser">
          <img
            src={UserAvatar}
            alt=""
            onClick={() => handleShowProfileMenu()}
          />
          <span onClick={() => handleShowProfileMenu()}>
            {general?.userData.email}
          </span>
          <img
            className="arrow"
            src={arrowUser}
            alt=""
            onClick={() => handleShowProfileMenu()}
          />

          {/* <ul className="dropdown-menu show"> */}
          <ul
            className={showProfileMenu ? "dropdown-menu show" : "dropdown-menu"}
          >
            <li className="name">{general?.userData.email}</li>
            <li>
              <a class="dropdown-item" href="#">
                Profile
              </a>
            </li>

            <li>
              <a class="dropdown-item" href="#">
                Settings
              </a>
            </li>
            <li>
              <a class="dropdown-item" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
