import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import AlertMessage from "../../../components/clientAdmin/AlertMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";

import {
  searchClientSecurityGroups,
  searchClientUsers,
  createClientUserSecurityGroupLink,
} from "../../../services/clientAdmin";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";

function ClientGroupMap({ instance }) {
  const [data, setData] = useState(false);
  const [clientUsers, setClientUsers] = useState(false);
  const [clientGroups, setClientGroups] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "security groups" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function mapClientGroup(params) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    createClientUserSecurityGroupLink(params, user)
      .then((res) => {
        let succesMessage = "Client user group maping created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client/security-groups");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          setShowLoader(false);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      });
  }

  async function getClientUsers() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClientUsers(searchValue, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientUsers(results);
  }
  async function getClientGroups() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClientSecurityGroups(searchValue, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientGroups(results);
  }

  useEffect(() => {
    let ignore = false;
    getUserData();
    getClientUsers();
    getClientGroups();
    setShowLoader(false);
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client User Group Map</h1>              
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && (
              <AlertMessage alertMessage={error} alertType={"error"} />
            )}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={{
                  securityGroupId: "6b9cada1-8720-4c2b-141c-08dbc8a94ae5",
                  userId: "66526a7b-773d-4055-a52c-08dbc14f7b60",
                }}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  mapClientGroup(JSON.stringify(values, null, 2));
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Users
                                  </label>
                                  <div className="col-md-9">
                                    {clientUsers && (
                                      <Field
                                        name="userId"
                                        className="form-control"
                                        as="select"
                                      >
                                        <option key="" value="">
                                          Select User
                                        </option>
                                        {clientUsers.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Security Group
                                  </label>
                                  <div className="col-md-9">
                                    {clientGroups && (
                                      <Field
                                        name="securityGroupId"
                                        className="form-control"
                                        as="select"
                                      >
                                        <option key="" value="">
                                          Select Security Group
                                        </option>
                                        {clientGroups.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client/security-groups`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientGroupMap;
