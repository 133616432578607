import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage, Checkbox } from "formik";
import {
  searchClientSecurityGroups,
  searchClientWorkspaces,
  createClientWorkspaceSecurityGroupLink,
  searchClients,
} from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";

function ClientWorkspaceGroupMap({ instance }) {
  const [data, setData] = useState(false);
  const [clientWorkspaces, setClientWorkspaces] = useState(false);
  const [clientGroups, setClientGroups] = useState(false);
  const [clientList, setClientList] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const { clientId, resourceId } = useParams();
  const [error, setError] = useState(null);
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  const mapClientWorkspace = (params) => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    createClientWorkspaceSecurityGroupLink(clientId, params, user)
      .then((res) => {
        let succesMessage =
          "Client workspace and group maping created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };

  const getClientList = async () => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClients("", user);
    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientList(results);
  };

  async function getClientWorkspaces(otherClientId = null) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let fetchClientId = clientId;
    if (otherClientId !== null) {
      fetchClientId = otherClientId;
    }
    const response = await searchClientWorkspaces(fetchClientId, user);

    const results = [];
    // Store results in the results array
    response.data.workspaces.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientWorkspaces(results);
  }
  async function getClientGroups(otherClientId = null) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let fetchClientId = clientId;
    if (otherClientId !== null) {
      fetchClientId = otherClientId;
    }
    const response = await searchClientSecurityGroups(fetchClientId, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientGroups(results);
  }
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        //dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  useEffect(() => {
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");

    let ignore = false;
    getUserData();
    getClientWorkspaces();
    getClientGroups();
    getClientList();
    setShowLoader(false);
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client Workspace Group Map</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={{
                  createFlag: false,
                  editFlag: false,
                  viewFlag: false,
                  exportFlag: false,
                  subscribeFlag: false,
                  enableQnAFlag: false,
                  isAdminFlag: false,
                  securityGroupId: "6b9cada1-8720-4c2b-141c-08dbc8a94ae5",
                  workspaceId: "66526a7b-773d-4055-a52c-08dbc14f7b60",
                  clientIdForGroup: "",
                  clientIdForworkspace: "",
                }}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  mapClientWorkspace(JSON.stringify(values, null, 2));
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Client
                                  </label>
                                  <div className="col-md-9">
                                    {clientList && (
                                      <Field
                                        name="clientIdForworkspace"
                                        className="form-control"
                                        as="select"
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setFieldValue(
                                            "clientIdForworkspace",
                                            selectedValue
                                          );
                                          getClientWorkspaces(e.target.value);
                                        }}
                                      >
                                        <option key="" value="">
                                          Select Client
                                        </option>
                                        {clientList.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Workspace
                                  </label>
                                  <div className="col-md-9">
                                    {clientWorkspaces && (
                                      <Field
                                        name="workspaceId"
                                        className="form-control"
                                        as="select"
                                      >
                                        <option key="" value="">
                                          Select Workspace
                                        </option>
                                        {clientWorkspaces.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Client
                                  </label>
                                  <div className="col-md-9">
                                    {clientList && (
                                      <Field
                                        name="clientIdForGroup"
                                        className="form-control"
                                        as="select"
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setFieldValue(
                                            "clientIdForGroup",
                                            selectedValue
                                          );
                                          getClientGroups(e.target.value);
                                        }}
                                      >
                                        <option key="" value="">
                                          Select Client
                                        </option>
                                        {clientList.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Security Group
                                  </label>
                                  <div className="col-md-9">
                                    {clientGroups && (
                                      <Field
                                        name="securityGroupId"
                                        className="form-control"
                                        as="select"
                                      >
                                        <option key="" value="">
                                          Select Security Group
                                        </option>
                                        {clientGroups.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  ></label>
                                  <div className="col-md-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="createFlag"
                                        className="form-check-input"
                                      />
                                      Create Flag
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  ></label>
                                  <div className="col-md-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="editFlag"
                                        className="form-check-input"
                                      />
                                      Edit Flag
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  ></label>
                                  <div className="col-md-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="viewFlag"
                                        className="form-check-input"
                                      />
                                      View Flag
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  ></label>
                                  <div className="col-md-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="exportFlag"
                                        className="form-check-input"
                                      />
                                      Export Flag
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  ></label>
                                  <div className="col-md-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="subscribeFlag"
                                        className="form-check-input"
                                      />
                                      Subscribe Flag
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  ></label>
                                  <div className="col-md-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="enableQnAFlag"
                                        className="form-check-input"
                                      />
                                      Enable QnA Flag
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  ></label>
                                  <div className="col-md-9">
                                    <div className="d-flex align-items-center gap-2">
                                      <Field
                                        type="checkbox"
                                        name="isAdminFlag"
                                        className="form-check-input"
                                      />
                                      is Admin Flag
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client-security-groups/${clientId}`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientWorkspaceGroupMap;
