import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import {
  searchClients,
  getWebsiteNameLogo,
  getEmbedToken,
  getPbiResources,
} from "../../../services";
import { Link } from "react-router-dom";

import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
} from "../../../store/features/generalSlice";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";

function MobileReports({ instance }) {
  const navigate = useNavigate();
  const [report, setReport] = useState();
  const [user, setUser] = useState();
  const [embedToken, setEmbedToken] = useState("");
  const clientId = "5b50d8c1-9151-4cbe-fa12-08dbe67a40e2";

  function getEmbedTokens() {
    console.log("response", user);
    const body = {
      datasets: [
        {
          id: "7621dfb6-ceda-4130-8a45-958937beed2f",
        },
      ],
      reports: [
        {
          id: "20bb7f0a-684a-4560-aaba-3380ea3e2bd5",
        },
      ],
      identities: [
        {
          username: "aidi_testemployee_1@dapasol.com",
          roles: ["Demo"],
          datasets: ["7621dfb6-ceda-4130-8a45-958937beed2f"],
        },
      ],
    };
    getEmbedToken(clientId, body, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(res.data);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  function getPbiResourceData() {
    getPbiResources({}, user)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function reportMobileLayout() {
    console.log("report maan", report);
    if (report) {
      report.setComponentTitle("Embedded Report");
      let pages = await report.getPages();
      let mobileLayout = pages[0].hasLayout(models.LayoutType.MobilePortrait);
      console.log("report maan");
    }
  }
  useEffect(() => {
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    console.log(vh);
    if (report) {
      report.setComponentTitle("Embedded Report");
      console.log("report maan");
    }
    let userData = localStorage.getItem("pbUser");
    let accessToken = "";
    if (userData) {
      userData = JSON.parse(userData);
      accessToken = userData.accessToken;
    } else {
      userData = null;
    }
    setUser({
      token: accessToken,
    });
    if (accessToken != "") {
      getEmbedTokens();
      //getPbiResources();
    }
  }, []);

  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-header border-bottom">
                    <div className="d-flex flex-wrap">
                      <Link to="/reports" className="btn btn-primary">
                        Desktop View
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <PowerBIEmbed
                      embedConfig={{
                        type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                        id: "20bb7f0a-684a-4560-aaba-3380ea3e2bd5",
                        embedUrl:
                          "https://app.powerbi.com/reportEmbed?reportId=20bb7f0a-684a-4560-aaba-3380ea3e2bd5&groupId=92ebdba0-2b3c-4015-b86d-8e003a2dd37a&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUlORElBLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
                        accessToken: embedToken,
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                        settings: {
                          panes: {
                            filters: {
                              expanded: false,
                              visible: false,
                            },
                          },
                          layoutType: models.LayoutType.MobilePortrait,
                        },
                      }}
                      eventHandlers={
                        new Map([
                          [
                            "loaded",
                            function () {
                              console.log("Report loaded");
                            },
                          ],
                          [
                            "rendered",
                            async function () {
                              console.log("Report rendered");
                              reportMobileLayout();
                            },
                          ],
                          [
                            "error",
                            function (event) {
                              console.log(event.detail);
                            },
                          ],
                          [
                            "visualClicked",
                            () => console.log("visual clicked"),
                          ],
                          ["pageChanged", (event) => console.log(event)],
                        ])
                      }
                      cssClassName={"reportClass"}
                      getEmbeddedComponent={async (embeddedReport, report) => {
                        window.report = embeddedReport;
                        setReport(embeddedReport);
                        console.log("setReport", report);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainLayout>
    </MsalProvider>
  );
}

export default MobileReports;
