import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { getResourceEmbedToken } from "../../services/clientAdmin";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../store/features/generalSlice";

function PowerBIEmbedView({ pbiResource, clientData }) {
  console.log("pbiResource", pbiResource);
  const [accessToken, setAccessToken] = useState("");
  const [report, setReport] = useState();
  const [showLoader, setShowLoader] = useState(true);
 
  const [userEmail, setUserEmail] = useState("aidi_testemployee_1@dapasol.com");
  const [embedToken, setEmbedToken] = useState("");
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);        
      }
    }
    return pbUser;
  }
  let body = {};

  async function getEmbedTokens() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };      
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    
    getResourceEmbedToken(pbiResource.id, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(res.data);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
    getEmbedTokens();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="card-body">
      {pbiResource.type === "Paginated Report" && (
        <PowerBIEmbed
          embedConfig={{
            type: pbiResource.type.toLowerCase(), // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              //layoutType: models.LayoutType.MobilePortrait,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={async (embeddedReport, report) => {
            window.report = embeddedReport;
            setReport(embeddedReport);
            console.log("setReport", report);
          }}
        />
      )}
      {pbiResource.type === "Report" && (
        <PowerBIEmbed
          embedConfig={{
            type: pbiResource.type.toLowerCase(), // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              //layoutType: models.LayoutType.MobilePortrait,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={async (embeddedReport, report) => {
            window.report = embeddedReport;
            setReport(embeddedReport);
            console.log("setReport", report);
          }}
        />
      )}
      {pbiResource.type === "Dashboard" && (
        <PowerBIEmbed
          embedConfig={{
            type: "dashboard", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              //layoutType: models.LayoutType.MobilePortrait,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={async (embeddedReport, report) => {
            window.report = embeddedReport;
            setReport(embeddedReport);
            console.log("setReport", report);
          }}
        />
      )}
      {pbiResource.type === "Tile" && (
        <PowerBIEmbed
          embedConfig={{
            type: "tile", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: pbiResource.details.id,
            embedUrl: pbiResource.details.embedUrl,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              //layoutType: models.LayoutType.MobilePortrait,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"reportClass"}
          getEmbeddedComponent={async (embeddedReport, report) => {
            window.report = embeddedReport;
            setReport(embeddedReport);
            console.log("setReport", report);
          }}
        />
      )}
      {pbiResource.type === "Dataset" && (
        <PowerBIEmbed
          embedConfig={{
            type: "qna", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            datasetIds: [pbiResource.resourceId],
            embedUrl: pbiResource.details.qnaEmbedURL,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              // layoutType: models.LayoutType.MobilePortrait,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                async function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],              
            ])
          }
          cssClassName={"reportClass"}          
        />
      )}
    </div>
  );
}

export default PowerBIEmbedView;
