import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";

import {
  updatePbIResourceData,
  getPbiResources,
} from "../../../services/clientAdmin";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import AlertMessage from "../../../components/clientAdmin/AlertMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientResourceUpdate({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resourceId } = useParams();
  dispatch(setActiveMenu({ activeMenu: "activity log" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function startFetching() {
    let user = false;

    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
      setSearchValue(pbUser.userId);
    } else {
      user = {
        token: userData.accessToken,
      };
      setSearchValue(userData.userId);
    }
    console.log(searchValue);
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await getPbiResources(resourceId, user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("client/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);
      setShowLoader(false);
    }
  }
  const clearSearch = () => {
    setSearchValue("");
    startFetching();
  };
  const updateResource = (params) => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    updatePbIResourceData(resourceId, params, user)
      .then((res) => {
        let succesMessage = "Client resource created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client/dashboard");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Resource Data Update</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={{
                  aliasName: data.aliasName,
                  description: data.description,
                  status: true,
                }}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  updateResource(JSON.stringify(values, null, 2));
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Alias Name
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="aliasName"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Description
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      as="textarea"
                                      name="description"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client/embeded-view/${resourceId}`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}
export default ClientResourceUpdate;
