import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReportAccordion from "./ReportAccordion";
import DashboardAccordion from "./DashboardAccordion";
import DatasetAccordion from "./DatasetAccordion";

import { useSelector, useDispatch } from "react-redux";
import {
  setClientReports,
  setClientDashboard,
  setClientDataset,
} from "../../../store/features/generalSlice";
import {
  searchPbiReports,
  searchPbiDashboard,
  searchPbiDataset,
} from "../../../services";

function ResourseTypeTab({ workspaceId = null, clientId = null }) {
  ////console.log("ResourseTypeTab", workspaceId, clientId);
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  async function getPbiReports(key) {
    let resourseType = key.split("-");
    let user = false;
    user = {
      token: userData.accessToken,
    };
    let ignore = false;
    // let response = false;
    // if (resourseType[0] === "report") {
    //   //response = await searchPbiReports(clientId, workspaceId, user);
    // }
    // if (resourseType[0] === "dashboard") {
    //   //response = await searchPbiDashboard(clientId, workspaceId, user);
    // }
    // if (resourseType[0] === "dataset") {
    //   //response = await searchPbiDataset(clientId, workspaceId, user);
    // }

    const reportData =
      general?.wholePbiResources &&
      general?.wholePbiResources[clientId] &&
      general?.wholePbiResources[clientId]["pbiResources"] &&
      general?.wholePbiResources[clientId]["pbiResources"][workspaceId]
        ? general.wholePbiResources[clientId]["pbiResources"][workspaceId]
        : [];
    //console.log("reportData", reportData);

    const data = reportData.reduce((accumulator, item) => {
      // Get the category of the current item

      const type = item.type;

      // Initialize the category array if not already initialized

      if (!accumulator[type]) {
        accumulator[type] = [];
      }

      // Add the current item to its category array

      accumulator[type].push(item);

      return accumulator;
    }, {});
    
    // Update the options state

    if (resourseType[0] === "report") {
      dispatch(setClientReports({ clientReports: data["Report"] }));
      //console.log("reports", data["Report"]);
    }
    if (resourseType[0] === "dashboard") {
      // Update the options state
      dispatch(setClientDashboard({ clientDashboard: data["Dashboard"] }));
      //console.log("Dashboard", data["Dashboard"]);
    }
    if (resourseType[0] === "dataset") {
      // Update the options state
      dispatch(setClientDashboard({ clientDataset: data["Dataset"] }));
      //console.log("Dashboard", data["Dashboard"]);
    }
    //console.log("clientReports", general?.clientReports);
    return;
  }

  return (
    <Tabs
      defaultActiveKey={"report-" + workspaceId}
      id={"uncontrolled-tab-example" + workspaceId}
      className=""
      //onSelect={getPbiReports}
    >
      <Tab eventKey={"report-" + workspaceId} title="Report">
        <div className="clients-tab-inner">
          <ReportAccordion workspaceId={workspaceId} clientId={clientId} />
        </div>
      </Tab>
      <Tab eventKey={"dashboard-" + workspaceId} title="Dashboard">
        <div className="clients-tab-inner">
          <DashboardAccordion workspaceId={workspaceId} clientId={clientId} />
        </div>
      </Tab>
      <Tab eventKey={"dataset-" + workspaceId} title="Dataset">
        <div className="clients-tab-inner">
          <DatasetAccordion workspaceId={workspaceId} clientId={clientId} />
        </div>
      </Tab>
    </Tabs>
  );
}

export default ResourseTypeTab;
