import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import { createClient } from "../../../services";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientCreate({ instance }) {
  const [name, setName] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  const saveClient = (params) => {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    createClient(params, user)
      .then((res) => {
        let succesMessage = "Client created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  };
  useEffect(() => {
    getUserData();
    setShowLoader(false);
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client Creation</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={{
                  website: "https://www.NewCompany.com",
                  name: "NewCompany",
                  legalEntityType: "Corporation",
                  hostingDomain: "NewCompany.com",
                  uniqueSubDomain: "NewCompany",
                  pan: "ABCDE15654F",
                  tan: "T656234567A",
                  gstin: "GSTI5657X",
                  social: {
                    facebook: "https://www.facebook.com/NewCompany",
                    twitter: "https://twitter.com/NewCompany",
                    linkedIn: "https://www.linkedin.com/company/NewCompany",
                    instagram: "https://www.instagram.com/NewCompany",
                  },
                  address: [
                    {
                      type: "Headquarters",
                      city: "New York",
                      state: "NY",
                      district: "Manhattan",
                      country: "USA",
                      pinCode: "10001",
                    },
                  ],
                  contact: [
                    {
                      name: "John Doe",
                      designation: "CEO",
                      email: "john.doe@NewCompany.com",
                      phone: "11234567890",
                    },
                  ],
                  loginDomains: ["example.com"],
                  primaryColor: "#233445",
                  secondaryColor: "#FGYUGH",
                }}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  saveClient(JSON.stringify(values, null, 2));
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Name
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="name"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Website
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="website"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Legal Entity Type
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="legalEntityType"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Hosting Domain
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="hostingDomain"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Unique Sub Domain
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="uniqueSubDomain"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Login Domains
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="loginDomains[0]"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>Contact Details</div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Name
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].name"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Designation
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].designation"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Email
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].email"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Phone
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="contact[0].phone"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div>Address Details</div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Type
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].type"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    City
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].city"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    State
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].state"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    District
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].district"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Country
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].country"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Pincode
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="address[0].pinCode"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    PAN
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="pan"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    TAN
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="tan"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    GSTIN
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="gstin"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Facebook
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.facebook"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Twitter
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.twitter"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    LinkedIn
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.linkedIn"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Instagram
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="social.instagram"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Primary Color
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="primaryColor"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    secondary Color
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="secondaryColor"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client-list`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientCreate;
