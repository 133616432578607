import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import {
  setClientReportPages,
  setUserData,
} from "../../../store/features/generalSlice";

import SliderImageReport from "../../../assets/theme/images/Report-1.png";
import SliderImageDashboard from "../../../assets/theme/images/Dashboard.png";
import SliderImageQna from "../../../assets/theme/images/qna.png";
import { getUserFavorites } from "../../../services/user";

function MyFavourites() {
  const [data, setData] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  const userData = useSelector((state) => state.general.userData);
  //console.log("ReportAccordion", workspaceId, clientId);
  const general = useSelector((state) => state.general);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    let ignore = false;
    const response = await getUserFavorites(user).catch((error) => {
      console.log(error);
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);
      console.log("data", data);

      setShowLoader(false);
    }
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();

    console.log("data", data);
    return () => {
      ignore = true;
    };
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    lap: {
      breakpoint: { max: 1300, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Col className="top-slider">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerclassName="carousel-container"
        removeArrowOnDeviceType={["", ""]}
        dotListclassName="custom-dot-list-style"
        itemclassName="carousel-item-padding-40-px"
      >
        {data &&
          data.length > 0 &&
          data.map((item) => (
            <div>
              <Link
                className="cell"
                key={item.id}
                to={`/user/embeded-view/${item.resourceId}`}
              >
                {item.type == "Report" && (
                  <img src={SliderImageReport} alt="" />
                )}
                {item.type == "Dashboard" && (
                  <img src={SliderImageDashboard} alt="" />
                )}
                {item.type == "Dataset" && <img src={SliderImageQna} alt="" />}
                <div className="text">
                  <span className="title">{item.type}</span>
                  <p>{item.details.aliasName}</p>
                  <span className="name">{item.details.name}</span>
                </div>
              </Link>
            </div>
          ))}
        {/* <div>
          <div className="cell">
            <img src={SliderImageDashboard} alt="" />
            <div className="text">
              <span className="title">Dashboard</span>
              <p>Dashboard 2</p>
              <span className="name">Group Name</span>
              <span className="time-date">20 Aug 2023 - 02:10PM</span>
            </div>
          </div>
        </div>
        <div>
          <div className="cell">
            <img src={SliderImageQna} alt="" />
            <div className="text">
              <span className="title">QnA</span>
              <p>QnA 3</p>
              <span className="name">Group Name</span>
              <span className="time-date">20 Aug 2023 - 02:10PM</span>
            </div>
          </div>
        </div>
        <div>
          <div className="cell">
            <img src={SliderImageDashboard} alt="" />
            <div className="text">
              <span className="title">Dashboard</span>
              <p>Dashboard 2</p>
              <span className="name">Group Name</span>
              <span className="time-date">20 Aug 2023 - 02:10PM</span>
            </div>
          </div>
        </div> */}
      </Carousel>
    </Col>
  );
}

export default MyFavourites;
