import { routes, get, post } from "./index"

export const applicationToken = (user, platform) => {
  return get(`${routes.applicationToken}` + `/${platform}`, { user })
}
export const getWebsiteNameLogo = (user, subdomain) => {
  return get(`${routes.getWebsiteNameLogo}` + `/?uniqueSubDomain=${subdomain}`, { user })
}
export const login = (body, user) => {
  return post(`${routes.login}`, { body, user });
};


  