import { useState, useEffect, useId } from "react";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_B3xDbGHbgEhepsfreR2mIKO_BQA2ZbY",
  authDomain: "aidi-b4a39.firebaseapp.com",
  projectId: "aidi-b4a39",
  storageBucket: "aidi-b4a39.appspot.com",
  messagingSenderId: "649824239593",
  appId: "1:649824239593:web:fc42f8e02fd7ea1d795578",
  measurementId: "G-00WBMKS70Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);