import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import {
  getEmbedToken,
  getResourceEmbedToken,
} from "../../services/clientAdmin";
import { getEmbedToken as pbiGetEmbedToken } from "../../services";
import { createActivityLog } from "../../services/user";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
  setPowerBIEmbedResource,
} from "../../store/features/generalSlice";

function PowerBITileEmbedView({ pbiResource, tileData }) {
  console.log("pbiResource", pbiResource);
  const [accessToken, setAccessToken] = useState("");
  const [report, setReport] = useState();
  const [showLoader, setShowLoader] = useState(true);

  const [userEmail, setUserEmail] = useState("aidi_testemployee_1@dapasol.com");
  const [embedToken, setEmbedToken] = useState("");
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(pbiResource.embedUrl);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
      }
    }
    return pbUser;
  }
  let body = {};

  async function getEmbedTokens() {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setShowLoader(true);
    getResourceEmbedToken(tileData.dashboardId, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(res.data);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function addActivityLog(log) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    let formData = {
      activity: log,
      message: log + " - ID: " + pbiResource.id,
      extra:
        log +
        " - ID: " +
        pbiResource.id +
        " - resourceID: " +
        pbiResource.resourceId,
    };

    createActivityLog(formData, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(general?.powerBIEmbedLayoutType);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
    getEmbedTokens();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="card-body">
      <PowerBIEmbed
        embedConfig={{
          type: "tile", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
          id: tileData.resourceId, //'4bff7fe9-aa2d-4eb6-8d29-05966f5458d6',//
          dashboardId: tileData.dashboardResourceId, //'6df8cbb6-841a-48a5-a0ea-369b12df366b',//
          embedUrl: tileData.embedUrl,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
        }}
        cssClassName={"reportClass"}
        getEmbeddedComponent={(embedObject) => {
          console.log(
            `Embedded object of type "${embedObject.embedtype}" received`
          );

          setReport(embedObject);
          dispatch(
            setPowerBIEmbedResource({ powerBIEmbedResource: embedObject })
          );
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                addActivityLog("Tile loaded");
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              async function () {
                // Get the pages from the report
                addActivityLog("Tile rendered");
                console.log("Report rendered");
              },
            ],
            [
              "error",
              function (event) {
                addActivityLog("Error on loading Tile");
                console.log(event.detail);
              },
            ],
            [
              "visualClicked",
              async function (embeddedReport) {
                // Get the pages from the report
                addActivityLog("Tile Visual Clicked");
                console.log("Tile Visual Clicked");
              },
            ],
            [
              "pageChanged",
              async function (embeddedReport) {
                // Get the pages from the report
                addActivityLog("Tile Page Changed");
                console.log("Tile Page Changed");
              },
            ],
          ])
        }
        allowFullScreen={general?.isFullScreen}
        fullscreen={general?.isFullScreen}
      />
    </div>
  );
}

export default PowerBITileEmbedView;
