import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { searchClientSubscriptions } from "../../../services/clientAdmin";
import { subscriptionTriggerNow } from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import AlertMessage from "../../../components/clientAdmin/AlertMessage";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import SearchBlack from "../../../assets/theme/images/search-black.png";
import {
  graph2,
  reload2,
  lock2,
  users2,
  Edit2,
} from "../../../assets/theme/images/icons";

function ClientSubscriptions({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const { clientId } = useParams();
  const [filteredData, setFilteredData] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "subscriptions" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    
    setAlertMessage(await localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    
    let ignore = false;
    const response = await searchClientSubscriptions("", user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
          navigate("/client/error-page");
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      setData(response.data);
      setFilteredData(response.data);
      setShowLoader(false);
    }
  }
  async function triggerNow(item) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await subscriptionTriggerNow(item, user)
      .then((res) => {
        let succesMessage = "Client subscription triggered successfully";
        setAlertMessage(succesMessage);
        setShowLoader(false);
      })
      .catch((error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      });
  }
  const clearSearch = () => {
    setSearchValue("");
    setFilteredData(data);
  };

  const itemSearch = () => {
    if (!searchValue || searchValue == "" || searchValue == null) {
      return false;
    }
    const lowercasedFilter = searchValue.toLowerCase();
    const tempData = data.filter((item) => {
      return Object.keys(item).some((key) =>
        key == "emailIds" ||
        key == "name" ||
        key == "distributionList" ||
        key == "mobileNumbers" ||
        key == "subscriptionType" ||
        key == "resourceId"
          ? item[key]?.toLowerCase().includes(lowercasedFilter)
          : ""
      );
    });
    setFilteredData(tempData);
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-list-pg clearfix">
            <div className="title-top-wraper1">
              <h1>Client Subscriptions List</h1>

              <div className="btn-row-1">
                <Link
                  to={`/client/subscription-create/`}
                  className="btn btn-primary ms-1 btnstyle1"
                >
                  Add
                </Link>
              </div>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}

            {error && <AlertMessage alertMessage={error} alertType={"error"} />}

            <div className="main-bg-wraper--1">
              <div className="top--section1">
                <h4></h4>
                <div className="right-btns-row">
                  <div className="search-type-1">
                    <img src={SearchBlack} alt="" />
                    <input
                      placeholder="Search"
                      type="text"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <div className="btngroup1">
                    <button
                      className="button-new1 blue"
                      onClick={() => itemSearch()}
                    >
                      Filter
                    </button>
                    <button
                      className="button-new1 grey"
                      onClick={() => clearSearch()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <div className="listing-table-wraper">
                <div className="table-list-items">
                  <div className="table-responsive">
                    <table className="table table-borderless align-middle table-style-new1">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Distribution List</th>
                          <th>Mobile Numbers</th>
                          <th>Subscription Type</th>
                          <th>Resource ID</th>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.emailIds}</td>
                            <td>{item.distributionList}</td>
                            <td>{item.mobileNumbers}</td>
                            <td>{item.subscriptionType}</td>
                            <td>{item.resourceId}</td>

                            <td>
                              <ul className="action-icons-list">
                                <li>
                                  <Link
                                    to={`/client/subscription-update/${item.id}`}
                                  >
                                    <Edit2 />
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={() => triggerNow(item)}>
                                    <Edit2 />
                                  </Link>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                        {filteredData?.length <= 0 && (
                          <tr>
                            <td colSpan="5">
                              <span>No result found</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientSubscriptions;
