import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "./layout/MainLayout";
import { MsalProvider } from "@azure/msal-react";

function ErrorPage({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-header border-bottom">
                    <div className="d-flex flex-wrap">
                    <h5 className="card-title mb-0">Error Page</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <p>Something went wrong!, please try again</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </MainLayout>
    </MsalProvider>
  );
}

export default ErrorPage;
