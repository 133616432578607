import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReportAccordion from "./ReportAccordion";


function ClientGroupTabs() {
  return (
    <Tabs
    defaultActiveKey="group1"
    id="uncontrolled-tab-example"
    className=""
  >
    <Tab eventKey="group1" title="Group 1">
      <div className="clients-tab-inner">
        <h4>Reports</h4>
      <ReportAccordion />
      </div>
    </Tab>
    <Tab eventKey="group2" title="Group 2">
    Tab content for Home
    </Tab>
    <Tab eventKey="group3" title="Group 3">
      Tab content for Contact
    </Tab>
    {/* <Tab eventKey="contact" title="Contact" disabled>
      Tab content for Contact
    </Tab> */}
  </Tabs>
  )
}

export default ClientGroupTabs