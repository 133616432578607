import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from "react-dom/client";
import { AudioRecorder } from "react-audio-voice-recorder";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";

import {
  setClientGroups,
  setClientPbiResources,
  setWholePbiResources,
  setActivityLoader,
  setQnaQuery,
} from "../../../store/features/generalSlice";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import ResourseTypeTab from "./ResourseTypeTab";
import WidgetImage from "../../../assets/theme/images/widget-main.png";
import WidgetLogo from "../../../assets/theme/images/widget-logo.png";

import ChatIcon from "../../../assets/theme/images/chat-icon.png";
import AudioLoaderIcon from "../../../assets/theme/images/gray-loading-gif.gif";

import { searchPbiResources, audioTranscription } from "../../../services/user";
import DatasetEmbededView from "./DatasetEmbededView";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import {
  LoaderAcc,
  MaxIcon,
  XRound,
  MicIcon,
  SendIcon,
  Reload3,
} from "../../../assets/theme/images/icons";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  setWidgetDataset,
  setUserData,
} from "../../../store/features/generalSlice";
function Widget({ clientId }) {
  const general = useSelector((state) => state.general);
  const [showModal, setShowModal] = useState(false);
  const [qnaQry, setQnaQry] = useState("");
  const [widgetFullscreen, setWidgetFullscreen] = useState(false);
  const [showDatasetList, setShowDatasetList] = useState(true);
  const [showEmbededView, setShowEmbededView] = useState(false);
  const [datasetData, setDatasetData] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcriptionLoader, setTranscriptionLoader] = useState(false);
  const userData = useSelector((state) => state.general.userData);

  const handleClose = () => setShowModal(false);
  const dispatch = useDispatch();
  function showWidgetModal() {
    console.log("showDescriptionModal");
    setShowModal(!showModal);
  }

  function handleDatasetEmbededView(item) {
    console.log("handleDatasetEmbededView");
    setShowDatasetList(false);
    setShowEmbededView(true);
    setDatasetData(item);
  }
  function handleResetDataset() {
    console.log("handleDatasetEmbededView");
    setShowDatasetList(true);
    setShowEmbededView(false);
    setDatasetData(false);
  }
  function handleWidgetFullscreen() {
    setWidgetFullscreen(!widgetFullscreen);
  }

  const handleQnaSearch = async () => {
    let temp = datasetData;
    setDatasetData(false);
    setDatasetData(temp);
    console.log("qnaQry", qnaQry);
    dispatch(setQnaQuery({ qnaQuery: qnaQry }));
  };

  const addAudioElement = (blob) => {
    setQnaQry("");
    setTranscriptionLoader(true);

    console.log("osName", osName);
    let audiofile = "";
    if (osName == "ios" || osName == "Mac OS" || osName == "iOS" || osName.includes("iOS")) {
      audiofile = new File([blob], "audiofile.webm", {
        type: "video/webm,",
      });
    } else {
      audiofile = new File([blob], "audiofile.mp3", {
        type: "audio/mpeg",
      });
    }
    // const audioUrl = URL.createObjectURL(audiofile)

    //   const audio = document.createElement("audio");
    //   audio.src = audioUrl;
    //   audio.type = "audio/mpeg";
    //   audio.controls = true;
    //   document.body.appendChild(audio);

    setAudioBlob(audiofile);

    uploadAudio(audiofile);
  };
  const uploadAudio = async (blob) => {
    // try {
    let user = false;

    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const formData = new FormData();
    if (osName == "ios" || osName == "Mac OS" || osName.includes("iOS")) {
      formData.append("fileType", "webm");
    } else {
      formData.append("fileType", "mp3");
    }
    formData.append("audio", blob);
    console.log(formData);
    audioTranscription(formData, user, "multipart/form-data")
      .then((res) => {
        setQnaQry(res.data.text);
        setTranscriptionLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
    // } catch (e) {
    //   console.log(e);
    // }
  };
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);

        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  return (
    <>
      {general?.widgetDataset?.length > 0 && (
        <div className="widget-button" onClick={() => showWidgetModal()}>
          <img src={WidgetImage} alt="" />
        </div>
      )}
      <div
        className={
          showModal
            ? widgetFullscreen
              ? "widget-wrap open full-w"
              : "widget-wrap open "
            : "widget-wrap"
        }
      >
        <div className="widget-head">
          <div className="left">
            {/* <a href="#" className="logo">
              <img src={WidgetLogo} alt="" />
            </a> */}
            <span>Ask AiDi</span>
          </div>
          <div className="right">
            <div className="btns">
              <div className="btn" onClick={() => handleResetDataset()}>
                <Reload3 />
              </div>
              <div
                className="btn minimize-maximize"
                onClick={() => handleWidgetFullscreen()}
              >
                <MaxIcon />
              </div>
              <div className="btn close-widget" onClick={() => handleClose()}>
                <XRound />
              </div>
            </div>
          </div>
        </div>
        {showDatasetList && (
          <div className="mid-sec">
            <div className="inner h-100">
              <div className="chat-sec">
                <div className="chat-image">
                  <img src={ChatIcon} alt="" />
                </div>
                <div className="chat">
                  <span className="title">Select a Data Model</span>
                  <div className="buttons">
                    {general?.widgetDataset?.length > 0 &&
                      general?.widgetDataset.map((item) => (
                        <div
                          className="btn"
                          key={item.id}
                          onClick={() => handleDatasetEmbededView(item)}
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showEmbededView && (
          <div className={widgetFullscreen ? "mid-sec full-w" : "mid-sec"}>
            <DatasetEmbededView datasetData={datasetData} />
          </div>
        )}

        <div className="widget-footer">
          <hr />

          <div className="top">
            <div className="type-area">
              {transcriptionLoader && (
                <img className="audioLoad" src={AudioLoaderIcon} alt="" />
              )}
              <div id="audio-player"></div>
              <input
                placeholder="I would like to..."
                type="text"
                value={qnaQry}
                onChange={(e) => setQnaQry(e.target.value)}
              />
              <AudioRecorder
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                onNotAllowedOrFound={(err) => console.table(err)}
                  downloadOnSavePress={true}
                  downloadFileExtension="wav"
                mediaRecorderOptions={{
                  audioBitsPerSecond: 128000,
                }}
              />
              {/* <button className="voice">
                
              </button> */}
            </div>
            <button className="send" onClick={() => handleQnaSearch()}>
              <SendIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Widget;
