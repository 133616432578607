import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import {
  getEmbedToken,
  getResourceEmbedToken,
} from "../../services/clientAdmin";
import { getEmbedToken as pbiGetEmbedToken } from "../../services";
import { createActivityLog } from "../../services/user";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
  setPowerBIEmbedResource,
} from "../../store/features/generalSlice";

function PowerBIPaginatedEmbedView({ pbiResource, pageData }) {
  console.log("pbiResource", pbiResource);
  const [accessToken, setAccessToken] = useState("");
  const [report, setReport] = useState();
  const [showLoader, setShowLoader] = useState(true);

  const [userEmail, setUserEmail] = useState("aidi_testemployee_1@dapasol.com");
  const [embedToken, setEmbedToken] = useState("");
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(pbiResource.embedUrl);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
      }
    }
    return pbUser;
  }
  let body = {};

  async function getEmbedTokens() {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setShowLoader(true);
    getResourceEmbedToken(pbiResource.id, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(res.data);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function addActivityLog(log, logMessage = null) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let message = logMessage;
    if (logMessage == null) {
      message =
        log +
        " -: " +
        (pageData.displayName ? pageData.displayName : pageData.name);
    }

    let formData = {
      activity: log,
      message: message,
      extra:
        log +
        " - Page Name: " +
        (pageData.displayName ? pageData.displayName : pageData.name) +
        " - Report Name: " +
        (pbiResource.aliasName ? pbiResource.aliasName : pbiResource.name) +
        " - ID: " +
        pbiResource.id +
        " - resourceID: " +
        pbiResource.resourceId,
    };

    createActivityLog(formData, user)
      .then((res) => {
        setEmbedToken(res.data.token);
        console.log(general?.powerBIEmbedLayoutType);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
    getEmbedTokens();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="card-body">
      <PowerBIEmbed
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create

          uniqueId: pbiResource.resourceId,
          pageName: pageData.name,
          //visualName: '3c5db07cc095383b42a5',   //
          embedUrl: pbiResource.details.embedUrl,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
          settings: {
            layoutType: general?.powerBIEmbedLayoutType,
          },
        }}
        cssClassName={"reportClass"}
        getEmbeddedComponent={(embedObject) => {
          console.log(
            `Embedded object of type "${embedObject.embedtype}" received`
          );

          setReport(embedObject);
          dispatch(
            setPowerBIEmbedResource({ powerBIEmbedResource: embedObject })
          );
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                addActivityLog("Report Page loaded");
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              async function () {
                // Get the pages from the report
                addActivityLog("Report Page rendered");
                console.log("Report rendered");
              },
            ],
            [
              "error",
              function (event) {
                addActivityLog("Error on loading Report Page");
                console.log(event.detail);
              },
            ],
            [
              "visualClicked",
              async function (embeddedReport) {
                // Get the pages from the report

                addActivityLog(
                  "Report Visual Clicked",
                  "Report Visual Clicked " +
                    embeddedReport?.detail?.report?.displayName +
                    " -> " +
                    embeddedReport?.detail?.page?.displayName +
                    " -> " +
                    embeddedReport?.detail?.visual?.name
                );

                console.log("Report Visual Clicked");
              },
            ],
            [
              "pageChanged",
              async function (embeddedReport) {
                // Get the pages from the report
                addActivityLog(
                  "Report Page Viewed",
                  "Report Page Viewed -> " +
                    (pbiResource.aliasName
                      ? pbiResource.aliasName
                      : pbiResource.name) +
                    " -> " +
                    embeddedReport?.detail?.newPage?.displayName
                );
                console.log("Report Page Viewed");
              },
            ],
          ])
        }
        allowFullScreen={general?.isFullScreen}
        fullscreen={general?.isFullScreen}
      />
    </div>
  );
}

export default PowerBIPaginatedEmbedView;
