import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClientReportPages,
  setUserData,
} from "../../../store/features/generalSlice";
import { GetReportPages } from "../../../services";
import { createUserFav, exportResource } from "../../../services/user";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import VisualsAccordion from "./VisualsAccordion";
import { Link, useNavigate } from "react-router-dom";
import ResourceInfoModal from "./ResourceInfoModal";

import Eye from "../../../assets/theme/images/eye.png";
import Export from "../../../assets/theme/images/export.png";
import {
  Info,
  Pin,
  Edit,
  TrashTwo,
  DashboardTwo,
} from "../../../assets/theme/images/icons";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function TileAccordion() {
  const general = useSelector((state) => state.general);
  const [name, setName] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const userData = useSelector((state) => state.general.userData);
  //console.log("ReportAccordion", workspaceId, clientId);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function showDescriptionModal(item) {
    console.log("showDescriptionModal");
    setShowModal(true);
    setModalData(item);
  }
  const handleClose = () => setShowModal(false);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function createUserFavorites(resourceId) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let params = { resourceId: resourceId, type: "DashboardTile" };
    createUserFav(params, user)
      .then((res) => {
        let succesMessage = "Resource added successfully to the favorites list";
        localStorage.setItem("alertMessage", succesMessage);
        setAlertMessage(succesMessage);
        //navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  }
  async function exportDashboard(resourceId) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let params = {
      fileFormat: 1,
      resourceType: "Dashboard",
    };
    exportResource(resourceId, params, user)
      .then((res) => {
        let succesMessage = "Export request completed successfully.";
        localStorage.setItem("alertMessage", succesMessage);
        setAlertMessage(succesMessage);
        //navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        setShowLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
      });
  }
  return (
    <Accordion defaultActiveKey="">
      {general?.clientDashboardTiles.length > 0 &&
        general?.clientDashboardTiles.map((item) => (
          <Card key={item.id}>
            <Card.Header>
              <CustomToggle eventKey="0">
                <DashboardTwo />
                {item.aliasName ? item.aliasName : item.id}{" "}
              </CustomToggle>
              <div className="accordion-actions">
                <span></span>
                <div className="buttons">
                  <a href={`/user/embeded-view/${item.id}`}>
                    <img src={Eye} alt="" /> View
                  </a>
                </div>
                <div className="icons">
                  <Link onClick={() => showDescriptionModal(item)}>
                    <Info />
                  </Link>
                  <Link onClick={() => createUserFavorites(item.id)}>
                    <Pin />
                  </Link>
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="visual-accordion-wrap">
                {/* <VisualsAccordion /> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      <ResourceInfoModal
        show={showModal}
        handleClose={handleClose}
        modalData={modalData}
      />
    </Accordion>
  );
}

export default TileAccordion;
