import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActivityLoader, setQnaQuery } from "../../../store/features/generalSlice";
import { getPbiResources, getClient } from "../../../services/user";

import Graph from "../../../assets/theme/images/graph-1.png";
import { LoaderAcc } from "../../../assets/theme/images/icons";

import WidgetEmbededView from "../../../components/user/WidgetEmbededView";

function DatasetEmbededView({ datasetData }) {
console.log("DatasetEmbededView");
    
  const general = useSelector((state) => state.general);
  const [data, setData] = useState(false);
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const userData = useSelector((state) => state.general.userData);
  const dispatch = useDispatch();
  async function getUserData() {
    let pbUser = false;
    if (!userData) {
      pbUser = localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await getPbiResources(datasetData.id, user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);
      setShowLoader(false);
    }
  }
  useEffect(() => {
    let ignore = false;
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    
      <div className="inner h-100" >
        <div className="graph-1 h-100">
          {data && <WidgetEmbededView pbiResource={data} clientData={{}} />}
        </div>
      </div>
    
  );
}

export default DatasetEmbededView;
