import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setClientGroups,
  setClientPbiResources,
  setWholePbiResources,
  setActivityLoader,
} from "../../../store/features/generalSlice";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import ResourseTypeTab from "./ResourseTypeTab";
import AccordionArrow from "../../../assets/theme/images/down-arrow.svg";
import { searchPbiResources } from "../../../services/user";
import { LoaderAcc, ArrowAccRight } from "../../../assets/theme/images/icons";
// import ClientGroupTabs from "./ClientGroupTabs";

function CustomToggle({ children, eventKey }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");

  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    // console.log("totally custom!", clientId)
    getPbiResources(eventKey)
  );

  async function getPbiResources(workspaceId) {
    if (
      general?.wholePbiResources[workspaceId] 
    ) {
      dispatch(
        setActivityLoader({
          activityLoader: false,
        })
      );
      return;
    }
    setShowLoader(true);
    let user = false;
    user = {
      token: userData.accessToken,
    };
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await searchPbiResources("", workspaceId, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );

    if (!ignore) {
      //setData(response.data);
      let datasets = [];
      datasets[workspaceId] = {
        ...datasets[workspaceId],
        pbiResources: response.data.pbiResources,
      };
      dispatch(setWholePbiResources({ wholePbiResources: datasets }));

      setShowLoader(false);
      dispatch(
        setActivityLoader({
          activityLoader: false,
        })
      );
    }
  }

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
function ClientWorkspacesAccordian({ clientId }) {
  const general = useSelector((state) => state.general);
  const workapaces = general?.clientWorkspaces ? general.clientWorkspaces : [];
  console.log(workapaces);

  return (
    <Accordion defaultActiveKey={workapaces?.[0]?.workspaceId} className="clients-accordion-main">
      {workapaces.length > 0 &&
        workapaces.map((item) => (
          <Card key={item.id}>
            <Card.Header>
              <CustomToggle eventKey={item.workspaceId}>
                {item.workspaceName}
                <ArrowAccRight className="accArrowIcon" />
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={item.workspaceId}>
              <Card.Body>
                <ResourseTypeTab workspaceId={item.workspaceId} clientId={item.clientId} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      {general?.activityLoader && (
        <Card>
          <Card.Body className="page-accordion-wrap">
            <div className="acc-loader">
              <LoaderAcc />
            </div>
          </Card.Body>
        </Card>
      )}
      {workapaces.length <= 0 && (
        <Card>
          <Card.Body className="page-accordion-wrap">
            No Result found!
          </Card.Body>
        </Card>
      )}
    </Accordion>
  );
}

export default ClientWorkspacesAccordian;
