import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import { searchClients, pbiResourcesRefresh } from "../../../services";
import { Link, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
  setClientList,
} from "../../../store/features/generalSlice";
import MyFavourites from "./MyFavourites";
import ClientAccordion from "./ClientAccordion";

function Dashboard({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const general = useSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "dashboard" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    let ignore = false;
    const response = await searchClients(searchValue, user).catch((error) => {
      console.log(error);
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      dispatch(setClientList({ clientList: response.data }));

      setShowLoader(false);
    }
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        <div className="inner clearfix">
          <h1>Dashboard</h1>

          <div className="top-slider clearfix">
            <MyFavourites />
          </div>
          <div className="clients-sec">
            <div className="clients-top">
              <h2>Clients</h2>
              {alertMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-message">{alertMessage}</div>
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  <div className="alert-message">{error}</div>
                </div>
              )}
              <div className="filter-cell">
                <div className="search">
                  <img src="images/search-black.png" alt="" />
                  <input placeholder="Search Projects" type="text" />
                </div>
                <select name="" id="" className="nice-select">
                  <option value="">Filter</option>
                  <option value="">Filter</option>
                  <option value="">Filter</option>
                </select>
              </div>
            </div>

            <ClientAccordion />
          </div>
        </div>
      </MainLayout>
    </MsalProvider>
  );
}

export default Dashboard;
