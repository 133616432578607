import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { activeMenu } from "../../store/features/generalSlice";

import Navtab from "../../views/admin/dashboard/navtab";
function SidebarMenu() {
  const dispatch = useDispatch();
  const activeMenu = useSelector((state) => state.general.activeMenu);
  const general = useSelector((state) => state.general);
  useEffect(() => {
    // Initialize ClassyNav
  }, []);
  const burggerMenuClassName = (general?.menuOpenClose === true ? "left off" : "left") + (general?.burggerMenuOpenClose === true ? " pull" : "");
  return (
    <div className={burggerMenuClassName}>
      <Navtab/>
      {/* <nav id="sidebar" className="sidebar js-sidebar" expand="true">
        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="/">
            <span className="align-middle"></span>
          </a>

          <ul className="sidebar-nav">
            <li
              className={
                activeMenu == "dashboard"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a className="sidebar-link" href="/">
                <i className="align-middle" data-feather="sliders"></i>{" "}
                <span className="align-middle">Dashboard</span>
              </a>
            </li>

            <li
              className={
                activeMenu == "clients" ? "sidebar-item active" : "sidebar-item"
              }
            >
              <Link to="/client-list" className="sidebar-link">
                <i className="align-middle" data-feather="user"></i>{" "}
                <span className="align-middle">Clients</span>
              </Link>
            </li>
            <li
              className={
                activeMenu == "reports" ? "sidebar-item active" : "sidebar-item"
              }
            >
              <Link to="/reports" className="sidebar-link">
                <i className="align-middle" data-feather="user"></i>{" "}
                <span className="align-middle">Reports</span>
              </Link>
            </li>
            <li
              className={
                activeMenu == "qna" ? "sidebar-item active" : "sidebar-item"
              }
            >
              <Link to="/qna" className="sidebar-link">
                <i className="align-middle" data-feather="user"></i>{" "}
                <span className="align-middle">Qna</span>
              </Link>
            </li>
            <li
              className={
                activeMenu == "activity log"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <Link to="/activity-log" className="sidebar-link">
                <i className="align-middle" data-feather="user"></i>{" "}
                <span className="align-middle">Activity Log</span>
              </Link>
            </li>
            <li
              className={
                activeMenu == "activity log"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <Link to="/client-notifications" className="sidebar-link">
                <i className="align-middle" data-feather="user"></i>{" "}
                <span className="align-middle">Manage Notifications</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav> */}
    </div>
  );
}

export default SidebarMenu;
