import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { GetDashboardTiles } from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientDashboardTiles({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [reports, setReports] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [dashboards, setDashboards] = useState(null);
  const { dashboardId } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  console.log(dashboardId);
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await GetDashboardTiles(dashboardId, user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);
      setShowLoader(false);
    }
  }
  const clearSearch = () => {
    setSearchValue("");
    startFetching();
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <main className="content">
            <div className="container-fluid p-0">
              <h1 className="h3 mb-3">Client Workspace </h1>
              {alertMessage && (
                <div className="alert alert-success" role="alert">
                  <div className="alert-message">{alertMessage}</div>
                </div>
              )}

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header border-bottom">
                      <h5 className="card-title mb-0">Client Dashboard Tiles -</h5>
                      <div>
                        <div className="d-flex flex-wrap"></div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <div className="row g-3 g-lg-4">
                          <div className="col-lg-3 col-sm-6">
                            <label className="form-label fw-bold">Search</label>
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              aria-describedby="emailHelp"
                            />
                            <div id="" className="invalid-feedback">
                              Please provide a valid data.
                            </div>
                          </div>

                          <div className="col-md-12">
                            <button className="btn btn-primary">Filter</button>
                            <button className="btn btn-secondary">Clear</button>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <h5 className="card-title mb-0">Reports</h5>
                      {data && (
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover my-0">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Dashboard Id</th>
                                <th>Dashboard Resource Id</th>
                                <th >
                                Dataset Id
                                </th>
                                <th>Resource Id</th>
                                
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.dashboardId}</td>
                                  <td>{item.dashboardResourceId}</td>
                                  <td>{item.datasetId}</td>
                                  <td>{item.resourceId}</td>
                                 

                                  <td>
                                    <Link
                                      to={`/client-embeded-view/${item.dashboardId}/${item.dashboardId}`}
                                      className="btn btn-primary  mt-1"
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientDashboardTiles;
