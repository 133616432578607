import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { getActivityLog } from "../../../services/user";
import { searchTrace } from "../../../services";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

import SearchBlack from "../../../assets/theme/images/search-black.png";

import {
  graph2,
  reload2,
  lock2,
  users2,
  Edit2,
} from "../../../assets/theme/images/icons";

function ActivityLog({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  let sessionId = localStorage.getItem("sessionId");
  const [searchValue, setSearchValue] = useState(sessionId);
  const userData = useSelector((state) => state.general.userData);
  const [filteredData, setFilteredData] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "activity log" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function startFetching() {
    let user = false;

    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
      //setSearchValue(pbUser.userId);
    } else {
      user = {
        token: userData.accessToken,
      };
      // setSearchValue(userData.userId);
    }
    let sessionId = localStorage.getItem("sessionId");
    setSearchValue(sessionId);
    console.log(searchValue);
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await searchTrace(searchValue, user).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);
      setFilteredData(response.data);
      setShowLoader(false);
    }
  }
  const clearSearch = () => {
    setSearchValue("");
    setFilteredData(data);
  };

  const itemSearch = () => {
    if (!searchValue || searchValue == "" || searchValue == null) {
      return false;
    }
    const lowercasedFilter = searchValue.toLowerCase();
    const tempData = data.filter((item) => {
      return Object.keys(item).some((key) =>
      item[key] != null && (key == "activity" || key == "clientId" || key == "message" || key == "role" || key == "sessionId" || key == "traceId" || key == "userId" )
          ? item[key].toLowerCase().includes(lowercasedFilter)
          : ""
      );
    });
    setFilteredData(tempData);
  };

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          
            <div className="inner client-list-pg clearfix">
              <div className="title-top-wraper1">
                <h1>Activity Log</h1>
                <div className="btn-row-1"></div>
              </div>
              {alertMessage && (
                <AlertMessage
                  alertMessage={alertMessage}
                  alertType={"success"}
                />
              )}
              {error && (
                <AlertMessage alertMessage={error} alertType={"error"} />
              )}

              <div className="main-bg-wraper--1">
                <div className="top--section1">
                  <h4></h4>
                  <div className="right-btns-row">
                    <div className="search-type-1">
                      <img src={SearchBlack} alt="" />
                      <input
                        placeholder="Search"
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                    <div className="btngroup1">
                      <button
                        className="button-new1 blue"
                        onClick={() => itemSearch()}
                      >
                        Filter
                      </button>
                      <button
                        className="button-new1 grey"
                        onClick={() => clearSearch()}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div className="listing-table-wraper">
                  <div className="table-list-items">
                    <div className="table-responsive">
                      <table className="table table-borderless align-middle table-style-new1">
                        <thead>
                          <tr>
                            <th>Activity</th>
                            <th>Client ID</th>
                            <th>Created AT</th>
                            <th>Message</th>
                            <th>Role</th>
                            <th>Session ID</th>
                            <th>Trace ID</th>
                            <th>User ID</th>

                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.activity}</td>
                              <td>{item.clientId}</td>
                              <td>{item.createdAt}</td>
                              <td>{item.message}</td>
                              <td>{item.role}</td>
                              <td>{item.sessionId}</td>
                              <td>{item.traceId}</td>
                              <td>{item.userId}</td>

                              <td></td>
                            </tr>
                          ))}
                          {filteredData?.length <= 0 && (
                            <tr>
                              <td colSpan="5">
                                <span>No result found</span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ActivityLog;
