import React from "react";
import { Circles } from "react-loader-spinner";

const LoaderComponent = () => {
  return (
    <div class="main-loader fullSize">
      <div class="loader"></div>
    </div>
  );
};

export default LoaderComponent;