import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { activeMenu } from "../../store/features/generalSlice";
import Navtab from "../../views/clientAdmin/dashboard/navtab";
function SidebarMenu() {
  const dispatch = useDispatch();
  const activeMenu = useSelector((state) => state.general.activeMenu);
  const general = useSelector((state) => state.general);
  useEffect(() => {
    // Initialize ClassyNav
  }, []);
  const burggerMenuClassName =
    (general?.menuOpenClose === true ? "left off" : "left") +
    (general?.burggerMenuOpenClose === true ? " pull" : "");

  return (
    <div className={burggerMenuClassName}>
      <Navtab />
    </div>

    // <nav id="sidebar" className="sidebar js-sidebar" expand="true">
    //   <div className="sidebar-content js-simplebar">
    //     <a className="sidebar-brand" href="/">
    //       <span className="align-middle"></span>
    //     </a>

    //     <ul className="sidebar-nav">
    //       <li className={activeMenu == 'dashboard' ? "sidebar-item active" : "sidebar-item"  }>
    //         <a className="sidebar-link" href="/client/dashboard">
    //           <i className="align-middle" data-feather="sliders"></i>{" "}
    //           <span className="align-middle">Dashboard</span>
    //         </a>
    //       </li>

    //       <li className={activeMenu == 'client user' ? "sidebar-item active" : "sidebar-item"  }>
    //         <Link to="/client/users-list" className="sidebar-link">
    //           <i className="align-middle" data-feather="user"></i>{" "}
    //           <span className="align-middle">Client Users</span>
    //         </Link>
    //       </li>
    //       <li className={activeMenu == 'workspaces' ? "sidebar-item active" : "sidebar-item"  }>
    //         <Link to="/client/workspaces" className="sidebar-link">
    //           <i className="align-middle" data-feather="user"></i>{" "}
    //           <span className="align-middle">Workspaces</span>
    //         </Link>
    //       </li>
    //       <li className={activeMenu == 'security groups' ? "sidebar-item active" : "sidebar-item"  }>
    //         <Link to="/client/security-groups" className="sidebar-link">
    //           <i className="align-middle" data-feather="user"></i>{" "}
    //           <span className="align-middle">Security Groups</span>
    //         </Link>
    //       </li>
    //       <li className={activeMenu == 'subscriptions' ? "sidebar-item active" : "sidebar-item"  }>
    //         <Link to="/client/subscriptions" className="sidebar-link">
    //           <i className="align-middle" data-feather="user"></i>{" "}
    //           <span className="align-middle">Subscriptions</span>
    //         </Link>
    //       </li>
    //       <li className={activeMenu == 'favariouts' ? "sidebar-item active" : "sidebar-item"  }>
    //         <Link to="/client/favariouts" className="sidebar-link">
    //           <i className="align-middle" data-feather="user"></i>{" "}
    //           <span className="align-middle">Favariouts</span>
    //         </Link>
    //       </li>
    //       <li className={activeMenu == 'manage notifications' ? "sidebar-item active" : "sidebar-item"  }>
    //         <Link to="/client/created-notifications" className="sidebar-link">
    //           <i className="align-middle" data-feather="user"></i>{" "}
    //           <span className="align-middle">Manage Notifications</span>
    //         </Link>
    //       </li>
    //       <li className={activeMenu == 'my notifications' ? "sidebar-item active" : "sidebar-item"  }>
    //         <Link to="/client/my-notifications" className="sidebar-link">
    //           <i className="align-middle" data-feather="user"></i>{" "}
    //           <span className="align-middle">My Notifications</span>
    //         </Link>
    //       </li>
    //     </ul>
    //   </div>
    // </nav>
  );
}

export default SidebarMenu;
